:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
