@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        margin-top: layout.$spacer-l;
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
      }

      .title {
        font-size: 0.75rem;
        font-weight: 500;
        color: colors.$mmc-disabled-gray;
      }
    }
  }
}
