@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .title {
        color: colors.$mmc-slate;
        font-weight: 500;
        text-transform: uppercase;
      }

      .groupHeader {
        background-color: colors.$mmc-soft-gray;
      }

      .groupName {
        background-color: colors.$mmc-white;
        padding: 0.25rem;
      }

      .collapse {
        padding: 0;
      }
    }
  }
}
