@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        max-width: 750px;
        padding: 0;

        & :global(.ant-modal-content) {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 119px);
        }

        & :global(.ant-modal-header) {
          flex: 0;
        }

        &.bordered {
          & :global(.ant-modal-body) {
            padding-top: 0.5rem;
          }

          & :global(.ant-modal-header) {
            border-bottom: 1px solid colors.$mmc-soft-gray;
          }
        }

        .header {
          align-content: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-right: 2.5rem;
        }

        .title {
          align-items: center;
          display: flex;
          flex-direction: row;
          font-size: 1.25rem;
          gap: 0.5rem;
          font-weight: 600;
          line-height: 1.75rem;
        }

        .subtitle {
          font-weight: initial;
        }

        &:not(.noScroll) {
          .content {
            padding-right: 1.25rem;
          }

          :global(.ant-modal-body) {
            flex: 1;
            padding: 0.5rem 0 1rem 1.25rem;
            overflow: hidden auto;
            overscroll-behavior: none;
          }
        }

        &.noScroll {
          .content {
            display: flex;
            flex-direction: column;
            flex: 1;
            flex-shrink: 0;
            overflow: hidden;
          }

          :global(.ant-modal-body) {
            display: flex;
            flex: 1;
            padding: 0;
            overflow: hidden;
          }
        }

        :global(.ant-modal-footer) {
          background: colors.$mmc-soft-gray;
          flex: 0;
          padding: 1rem 1.25rem;
        }

        &.small {
          & :global(.ant-modal-header) {
            padding: 0.5rem 1rem;
          }
        }

        .smallTitle {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.5rem;
        }

        .closeIcon {
          color: colors.$mmc-disabled-gray;
          font-size: 1rem;
          position: absolute;
          top: 1rem;
          right: 1.5rem;
        }
      }
    }
  }
}
