@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        text-align: left;
      }

      .row {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
      }

      .group {
        align-items: center;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.25rem;

        &.hover {
          background: colors.$mmc-white;
        }
      }

      .icon {
        color: colors.$mmc-disabled-gray;
        flex-shrink: 0;
        width: 1.5rem;

        &.active {
          color: colors.$mmc-primary;
        }
      }
    }
  }
}
