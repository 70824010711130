@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .row {
        // no styles, just for using with actions class
      }

      .recordType {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
      }

      .recordTypeIcon {
        width: 1rem;
        height: 1rem;
        font-size: 0.5rem;
        & > svg {
          width: 0.5rem;
          height: 0.5rem;
        }
      }

      .actions {
        visibility: hidden;
      }

      .row:hover .actions {
        visibility: visible;
      }
    }
  }
}
