@use "../../../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .line1 {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
        @include colors.rmd-theme(color, primary);
      }

      .line2,
      .line3 {
        font-size: 0.875rem;
        font-weight: 400;
      }

      .line3 {
        margin-bottom: 0;
      }

      .icon {
        margin-right: 0.5rem;
        @include colors.rmd-theme(color, primary);
      }
    }
  }
}
