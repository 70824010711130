:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        width: 100%;
      }

      .avatar {
        gap: 0.25rem;

        & > :global(.ant-avatar) {
          width: 1rem;
          height: 1rem;

          & > :global(.ant-avatar-string) {
            font-size: 0.75rem;
            line-height: 1rem;
          }
        }
      }
    }
  }
}
