@use "styles/colors";

$gradient-green: #24b275;
$gradient-light-green: #91d8ba;
$gradient-yellow: #f4c240;
$gradient-red: #ffabab;

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        width: 100%;

        border-radius: 0.5rem;
        padding-top: 0.5rem;
        position: relative;
      }
      .normalGradient {
        background: linear-gradient(
          90deg,
          $gradient-green 0%,
          $gradient-light-green var(--gradient-lightGreen-percentage),
          $gradient-yellow var(--gradient-yellow-percentage),
          $gradient-red var(--gradient-red-percentage)
        );
      }
      .twoDaysGradient {
        background: linear-gradient(
          -90deg,
          $gradient-red 30%,
          $gradient-light-green 60.5%,
          $gradient-green 100%
        );
      }
      .contentWrapper {
        overflow: hidden;
        border: 1px solid colors.$mmc-accent-gray;
        border-top: none;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        background: white;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
}
