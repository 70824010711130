// All classes defined here are global and should be created only when really needed.
// Prefer local classes in most cases.

.mmc-text-align-left {
  text-align: left;
}

.mmc-text-align-center {
  text-align: center;
}

.mmc-text-align-right {
  text-align: right;
}
