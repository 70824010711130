@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-size: 0.875rem;
        flex-wrap: wrap;
      }
      .subject {
        color: colors.$mmc-soft-slate;
      }
      .subjectText {
        color: colors.$mmc-slate;
      }
    }
  }
}
