@import "./mixins";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        flex-grow: 1;
        overflow: hidden;
      }

      .table {
        // sometimes AntD table uses incorrect width for an Empty component container and that
        // causes a horizontal scrollbar to appear. Here we try to fix that width problem.
        & :global(.ant-table-expanded-row-fixed) {
          width: unset !important; // need to override width given in style prop by antd itself
          right: 0;
        }
      }

      .scrollableTable {
        height: 100%;
        &.hasPagination {
          height: calc(100% - 48px);
        }

        // make table to expand vertically even if it has small amount of data
        & > :global(.ant-spin-nested-loading) {
          height: 100%;
          & > :global(.ant-spin-container) {
            height: 100%;
            & > :global(.ant-table) {
              height: 100%;
              padding: 0;

              & > :global(.ant-table-container) {
                height: 100%;
                & > :global(.ant-table-body) {
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .borderedTable {
        & :global(.ant-table) {
          border-width: 1px;
          border-style: solid;
          @include mmc-component-table-theme(border-color, border-color);
          border-radius: 0.25rem;
        }
      }

      .noHover {
        // update hover-vars locally in this table to have the same colors as not-hovered
        @include mmc-component-table-theme-update-var(
          row-odd-hover,
          mmc-component-table-theme-var(row-odd)
        );
        @include mmc-component-table-theme-update-var(
          selected-row-odd-hover,
          mmc-component-table-theme-var(selected-row-odd)
        );
        @include mmc-component-table-theme-update-var(
          row-even-hover,
          mmc-component-table-theme-var(row-even)
        );
        @include mmc-component-table-theme-update-var(
          selected-row-even-hover,
          mmc-component-table-theme-var(selected-row-even)
        );
      }

      .loading {
        opacity: 0.5;
      }

      .clickableRows {
        cursor: pointer;
      }

      :global(.ant-table-tbody) > tr {
        &.oddRow {
          @include mmc-component-table-theme(background-color, row-odd);

          &:hover > td {
            @include mmc-component-table-theme(background-color, row-odd-hover);
          }

          &:global(.ant-table-row-selected) {
            & > td {
              @include mmc-component-table-theme(background-color, selected-row-odd);
            }

            &:hover > td {
              @include mmc-component-table-theme(background-color, selected-row-odd-hover);
            }
          }
        }

        &.evenRow {
          @include mmc-component-table-theme(background-color, row-even);

          &:hover > td {
            @include mmc-component-table-theme(background-color, row-even-hover);
          }

          &:global(.ant-table-row-selected) {
            & > td {
              @include mmc-component-table-theme(background-color, selected-row-even);
            }

            &:hover > td {
              @include mmc-component-table-theme(background-color, selected-row-even-hover);
            }
          }
        }
      }
    }
  }
}
