@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        width: 1.5rem;
      }
      .l {
        width: 2rem;
      }
      .s {
        width: 1rem;
      }
      .xl {
        width: 7rem;
      }
    }
  }
}
