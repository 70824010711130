:global {
  body[class*="theme-"].theme {
    :local {
      .tag {
        height: 2rem;
        font-size: 1rem;
        font-weight: normal;
        display: flex;
        align-items: center;
        padding: 0.125rem 0.5rem;
      }
    }
  }
}
