@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
      .chip {
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        display: flex;
        gap: 0.25rem;
        align-items: center;
        height: 1.5rem;
        background-color: colors.$mmc-soft-gray;
      }
      .icon {
        height: 1rem;
      }
      .active {
        background-color: colors.$mmc-green-010;
      }
      .activeIcon {
        color: colors.$mmc-green-100;
      }
    }
  }
}
