@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: layout.$spacer-s layout.$spacer-l;
        background-color: colors.$mmc-yellow-025;
        font-weight: bold;
      }
    }
  }
}
