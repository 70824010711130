@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .submitButton {
        width: 100%;
      }

      .backToLoginLink {
        margin-top: 1.5rem;
        font-weight: 500;
        font-size: 0.875rem;
        text-align: center;
      }

      .alert {
        margin-bottom: 1rem;
        align-items: center;
      }

      .requestButton {
        height: 1.5rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
      }

      .requestSent {
        display: flex;
        align-items: center;
        color: colors.$mmc-slate;
        gap: 0.5rem;
      }
    }
  }
}
