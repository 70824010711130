@use "../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
      .variants {
        display: flex;
        gap: 0.5rem;
      }
      .option {
        padding: 0 0.5rem;
      }
      .customContainer {
        height: auto;
        display: flex;
        justify-content: center;
        align-self: center;
      }
      .intervalInDays {
        text-transform: uppercase;
        font-size: 0.75rem;
      }
      .whatsFrequencies {
        text-decoration: underline;
      }
      .customInterval {
        color: colors.$mmc-soft-slate;
        text-decoration: underline;
      }
      .loader {
        display: inline-flex;
      }
    }
  }
}
