@use "../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        width: auto;
        display: flex;
        gap: 1rem;
        border-radius: 0.25rem;
        border: 1px solid var(--pointer-border-color);
        background: var(--pointer-color);
        padding: 0.5rem 0.75rem;
        justify-content: space-between;
        align-items: center;
        margin: 0.3rem 0.25rem 0;
      }

      .pointerOuter {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--pointer-border-color);
        position: absolute;
        left: var(--pointer-pos);
        transform: translateX(-50%);
        top: 7px;
        z-index: 1;
      }
      .pointerInner {
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid var(--pointer-color);
        position: absolute;
        left: var(--pointer-pos);
        transform: translateX(-50%);
        top: 8px;
        z-index: 2;
      }

      .bubble {
        border: 1px solid var(--pointer-border-color);
        background: var(--pointer-color);
        border-radius: 8px;
        margin-top: -11px;
        padding: 0.5rem;
      }
      .title {
        font-weight: 500;
        font-size: 0.875rem;
      }
      .titleOverdue {
        color: colors.$mmc-red-100;
      }
      .subTitle {
        margin-top: 0.25rem;
        color: colors.$mmc-soft-slate;
        font-weight: 400;
        font-size: 0.75rem;
      }
      .stateIconContainer {
        width: 2rem;
        height: 2rem;
        background-color: colors.$mmc-white;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pointsContainer {
        position: absolute;
        top: -3px;
        z-index: 10;
        height: 0.875rem;
      }
      .leftItems {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
    }
  }
}
