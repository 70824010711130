@use "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
      }

      .icon {
        margin-right: 0.25rem;
        width: 0.7rem;
        height: 0.7rem;

        &.red {
          color: colors.$mmc-red-075;
        }

        &.green {
          color: colors.$mmc-green-100;
        }

        &.gray {
          color: colors.$mmc-disabled-gray;
        }
      }

      .email {
        font-size: 0.75rem;
        color: colors.$mmc-soft-slate;
        cursor: default;

        &:hover {
          color: colors.$mmc-slate;
        }
      }
    }
  }
}
