@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .restrictedUpload {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1rem;
        gap: 1rem;
        background-color: colors.$mmc-soft-gray;
        border: 1px dashed colors.$mmc-accent-gray;
        color: colors.$mmc-disabled-gray;
        font-style: italic;
      }
      .attachments {
        list-style-type: none;
        margin: 0;
        padding: 0;
        max-height: 7rem;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
      }
      .fileRow {
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
