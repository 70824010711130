@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .circle {
        border-radius: 50%;
      }

      .square {
        border-radius: 0.125rem;
        width: 1rem;
        height: 1rem;
        & > * {
          width: 0.5rem;
        }
      }

      .accounts {
        background-color: colors.$mmc-primary-100;
      }
      .contacts {
        background-color: colors.$mmc-green-100;
      }
      .deals {
        background-color: colors.$mmc-red-100;
      }
      .routes,
      .account-routes,
      .contact-routes {
        background-color: colors.$mmc-yellow-100;
      }
    }
  }
}
