@use "../../styles/colors" as *;

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        flex: 1;
      }

      .tag {
        max-width: 100%;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-word;
        margin: 0;
      }

      .completedTooltipWrapper {
        display: flex;
        flex-direction: column;
      }

      .completedLabel {
        font-size: 0.75rem;
        font-weight: bold;
        color: $mmc-soft-slate;
      }

      .date {
        font-size: 0.75rem;
        font-weight: 400;
        color: $mmc-soft-slate;
      }

      .durationWrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;
      }

      .checkIn,
      .sortFieldMessage {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        font-weight: 400;
        color: $mmc-primary;
        margin-top: 0.2rem;

        svg {
          margin-right: 0.325rem;
        }

        &.notVerified {
          color: $mmc-disabled-gray;
        }
      }

      .checkInDescription {
        font-size: 0.75rem;
        font-weight: 400;
        color: $mmc-soft-slate;
        max-width: 14rem;
      }

      .sortFieldMessage {
        color: $mmc-disabled-gray;
      }
    }
  }
}
