@import "../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: grid;
        grid-template-areas: "left main";
        grid-template-rows: 1fr 0;
        grid-template-columns: minmax(min-content, 30%) 1fr;

        .bottomPanel {
          display: none;
        }

        @media (max-width: #{$breakpoint-desktop}) {
          grid-template-columns: minmax(min-content, 40%) 1fr;
        }
        @media (max-width: #{$breakpoint-tablet}) {
          gap: 2rem;
          grid-template-areas:
            "main"
            "bottom";
          grid-template-rows: 1fr minmax(15rem, 30%);
          grid-template-columns: 100%;
          .bottomPanel {
            display: block;
          }
          .leftPanel {
            display: none;
          }
        }
        position: absolute;
        height: 100vh;
        width: 100%;
      }

      .leftPanel {
        grid-area: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-height: 100vh;
        background: #f4f7fd;
      }

      .main {
        grid-area: main;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
      }

      .bottomPanel {
        grid-area: bottom;
        width: 100%;
        background: white url("../../../assets/auth/registration-graphic-tablet.svg") repeat-x
          center top;
      }

      .leftLogo {
        margin: 5rem 0;
      }

      .leftImage {
        flex: 1;
        width: 100%;
        background: #f4f7fd url("../../../assets/auth/left-bg.svg") no-repeat top right;
      }

      .bottomImage {
        width: 100%;
      }
    }
  }
}
