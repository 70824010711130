@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        margin-top: 1rem;
        :global(.ant-modal-body) {
          padding-top: 1rem;
        }
      }
      .icon {
        float: left;
        margin-right: 1rem;
        margin-top: 0.25rem;
        color: colors.$mmc-primary-100;
      }
      .content {
        margin-left: 2rem;
      }
    }
  }
}
