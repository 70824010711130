@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
      }

      .description {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        line-height: 1.375rem;
        text-align: center;
      }

      .approved {
        color: colors.$mmc-green-100;
      }

      .denied {
        color: colors.$mmc-red-100;
      }

      .buttonContainer {
        display: flex;
        gap: 1rem;
      }
    }
  }
}
