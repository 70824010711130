@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        font-weight: normal;
      }

      .btn {
        background: none;
        border: none;
        border-radius: initial;
        box-shadow: none;
        color: colors.$mmc-soft-slate;
        height: auto;
        font-weight: normal;
        padding: 0;
        margin: 0;
        line-height: inherit;
        text-align: left;
        white-space: nowrap;
        font-weight: 500;

        &:not(:disabled) {
          &:active,
          &:focus,
          &:hover,
          &:global(.ant-dropdown-open) {
            background: none;
            color: colors.$mmc-primary;
          }
        }

        & > :global(.ant-btn):disabled {
          border: none;
          font-weight: normal;
          margin: 0;
          padding: 0;
        }
      }

      .menu {
        min-width: 15rem;
        user-select: none;

        &:global(.ant-dropdown-menu) {
          &.scrollable {
            // Allow up to 5 items without scrolling and compensate standard padding for menu
            max-height: calc(5 * 2rem + 0.5rem);
            overflow-x: hidden;
            overflow-y: auto;
          }
        }

        & > :global(.ant-dropdown-menu-item) {
          height: 2rem;
          padding-right: 2rem;
          max-width: 25rem;
          text-overflow: ellipsis;
          overflow: hidden;

          &:not(:global(.ant-dropdown-menu-item-active)):global(.ant-dropdown-menu-item-selected) {
            background: colors.$mmc-soft-gray;
            color: colors.$mmc-soft-slate;
          }

          &:global(.ant-dropdown-menu-item-active) {
            background: colors.$mmc-primary-005;
            color: colors.$mmc-primary;
          }
        }
      }

      .tooltip {
        :global(.ant-tooltip-inner) {
          color: colors.$mmc-white;
          background-color: colors.$mmc-slate;
        }
        :global(.ant-tooltip-arrow-content) {
          background-color: colors.$mmc-slate;
        }
      }
    }
  }
}
