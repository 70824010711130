@use "../../styles/colors" as *;

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .avatar {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 1rem;
        line-height: 1.6rem;
      }

      .chevron {
        font-size: 0.75rem;
        margin: 0 0.5rem;
        color: $mmc-soft-slate;
      }

      .differentAssigneeContainer {
        width: max-content;
      }
    }
  }
}
