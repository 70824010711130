:global {
  body[class*="theme-"].theme {
    :local {
      .fullWidth {
        width: 100%;
      }

      .findMeIcon {
        height: 1rem;
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }
  }
}
