@use "styles/layout";
@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: colors.$mmc-white;
      }
      .icon {
        color: colors.$mmc-yellow-100;
      }
      .title {
        font-weight: 600;
        font-size: 1.25rem;
        color: colors.$mmc-slate;
        text-align: center;
      }
      .subTitle {
        margin-top: 1rem;
        padding: 0.25rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;
        color: colors.$mmc-slate;
        background: colors.$mmc-yellow-010;
        width: 100%;
      }

      .row {
        width: auto;
        & :global(.ant-col) {
          padding: layout.$spacer-s;
        }
      }
      .duplicateContainer {
        width: 100%;
        &.withHeader {
          width: calc(100% - 1.5rem);
        }
      }
      .duplicateRow {
        background-color: colors.$mmc-soft-gray;
      }
      .more {
        width: 100%;
        font-size: 0.875rem;
        font-weight: 400;
        color: colors.$mmc-soft-slate;
      }
      .fields {
        color: colors.$mmc-disabled-gray;
      }
    }
  }
}
