@use "styles/colors";
@use "./activityTypeColors";

:global {
  body[class*="theme-"].theme {
    :local {
      .activityTypeIcon {
        display: inline-flex;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        position: relative;
        border-width: 1px;
        border-style: solid;
      }

      .autoEmail {
        position: absolute;
        right: -0.25rem;
        top: -0.25rem;
      }

      $activity-type-sizes: "sm" 1rem 0.5rem, "md" 1.5rem 0.75rem, "lg" 2rem 1rem;

      @each $name, $size, $fontSize in $activity-type-sizes {
        .#{$name} {
          width: $size;
          height: $size;
          font-size: $fontSize;

          & > svg {
            width: $fontSize;
          }
        }
      }

      @each $name, $mainColor, $bgColor in activityTypeColors.$mmc-activity-type-color-list {
        .#{$name} {
          color: $mainColor;
          background-color: $bgColor;
          border: 1px solid $mainColor;
        }
      }

      @each $name, $mainColor, $bgColor in activityTypeColors.$mmc-activity-type-custom-color-list {
        .custom_#{$name} {
          color: $mainColor;
          background-color: $bgColor;
          border: 1px solid $mainColor;
        }
      }
    }
  }
}
