@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        background: transparent;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .row {
        width: 70%;

        &.full {
          width: 100%;
        }

        :global(.ant-form-item) {
          margin: 0;
        }

        &:empty {
          display: none;
        }
      }

      .full {
        width: 100%;
      }

      .hidden {
        :global(.ant-form-item-control-input),
        :global(.ant-form-item-label) {
          display: none;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      .collapse {
        box-shadow: 0rem 0.25rem 0.75rem 0rem #0000000f;

        :global(.ant-collapse-header) {
          box-shadow: 0rem 0.25rem 0.75rem 0rem rgba(0, 0, 0, 0.03);
        }
      }

      .alert {
        background: colors.$mmc-red-010;
        border-radius: 0.25rem;
        border: 1px solid colors.$mmc-red-050;

        :global(.ant-collapse-content),
        :global(.ant-collapse-item),
        :global(.ant-collapse-item-active) {
          background: none;
          border: none;
        }

        :global(.ant-collapse-header) {
          font-weight: 700;
          box-shadow: none;
        }

        :global(.ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
          padding-top: 0;
        }
      }

      .icon {
        color: colors.$mmc-red-100;
      }

      .title {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
      }
    }

    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
      &::before {
        content: none;
        display: none;
      }

      &::after {
        color: colors.$mmc-red-100;
        content: "*";
        display: inline-block;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        margin-left: 0.25rem;
      }
    }
  }
}
