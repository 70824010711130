@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        & :global(.ant-modal-close) {
          display: none;
        }
        & :global(.ant-modal-content) {
          height: 100%;
        }
        & :global(.ant-modal-body) {
          padding: 0.75rem 0 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          min-height: 0;
          gap: 0.5rem;
        }
        height: 60vh;
        min-height: 16rem;
        min-width: 10rem;
        padding: 0;
      }
      .contentWrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
      }
      .listContainer {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .search {
        margin: 0 1rem;
      }
      .recentText {
        color: colors.$mmc-soft-slate;
        background-color: colors.$mmc-soft-gray;
        text-transform: uppercase;
        display: flex;
        padding-left: 1rem;
        align-items: center;
        font-weight: 500;
        min-height: 1.75rem;
      }
    }
  }
}
