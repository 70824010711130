@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        & :global(.ant-modal-body) {
          background-color: colors.$mmc-soft-gray;
          padding-right: 0;
          padding-bottom: 0;
          max-height: 70vh;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }
  }
}
