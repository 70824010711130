:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        position: relative;
      }

      .map {
        width: 100%;
        height: 100%;
      }

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        pointer-events: none;
      }
    }
  }
}
