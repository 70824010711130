:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        color: black;
        font-size: 0.875rem;
        font-weight: 400;
        border-width: 1px;
        border-style: solid;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 0.25rem;
      }
    }
  }
}
