@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .note {
        font-size: 0.875rem;
        font-weight: 400;
        color: colors.$mmc-slate;
        border: none;
        resize: none;
        overflow: auto;
        width: 100%;
        margin: 0;
        white-space: pre-wrap;

        &:focus {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}
