@use "styles/colors";
$lg-theme-color: colors.$mmc-white;
// have to use linear-gradient because this value is assigned to background-image prop
$lg-toolbar-bg: linear-gradient(0deg, colors.$mmc-white, colors.$mmc-white);
$lg-icon-bg: colors.$mmc-white;
$lg-icon-color: colors.$mmc-soft-slate;
$lg-icon-hover-color: colors.$mmc-slate;
$lg-toolbar-icon-color: colors.$mmc-soft-slate;
$lg-toolbar-icon-hover-color: colors.$mmc-slate;
@import "~lightgallery/scss/lightgallery";
@import "~lightgallery/scss/lg-zoom";
@import "~lightgallery/scss/lg-video";
