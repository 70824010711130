@use "../../styles/colors";
@use "../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .primary {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .name {
        color: colors.$mmc-primary;
        cursor: pointer;
      }
      .secondary {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
