@import "../../../../styles/layout";
$mmc-user-profile-info-avatar-size: 100px;

:global {
  body[class*="theme-"].theme {
    :local {
      .userProfile {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: $spacer-m $spacer-s;

        & > :global(.ant-avatar) {
          width: $mmc-user-profile-info-avatar-size;
          height: $mmc-user-profile-info-avatar-size;
          line-height: $mmc-user-profile-info-avatar-size;
          font-size: 1.5rem;
        }
      }

      .userProfileOverlay {
        width: $mmc-user-profile-info-avatar-size;
        height: $mmc-user-profile-info-avatar-size;
        margin-left: -$mmc-user-profile-info-avatar-size;
        border-radius: 50%;
        color: white;
        font-weight: 700;
        font-size: 0.8rem;
        background: rgba(40, 40, 40, 0.55);
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity ease-out 0.25s;
        &:hover {
          opacity: 1;
        }
      }

      .userDetails {
        padding-left: $spacer-m;
      }

      .editProfilePhotoModalContainer {
        display: flex;
        justify-content: center;
      }
    }
  }
}
