:global {
  body[class*="theme-"].theme {
    :local {
      .section {
        margin-top: 0;
      }

      .header {
        margin-bottom: 0.25rem;
      }
    }
  }
}
