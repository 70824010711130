:global {
  body[class*="theme-"].theme {
    :local {
      .nameField {
        & :global(.ag-cell-value) {
          width: 100%;
        }
      }
    }
  }
}
