@use "sass:color";

$mmc-primary-100: #3063e4;
$mmc-primary-075: #648aeb;
$mmc-primary-050: #97b1f1;
$mmc-primary-025: #cbd8f8;
$mmc-primary-010: #eaeffc;
$mmc-primary-005: #f5f7fe;

$mmc-red-100: #ff5858;
$mmc-red-075: #ff8282;
$mmc-red-050: #ffabab;
$mmc-red-025: #ffd5d5;
$mmc-red-010: #ffeeee;

$mmc-yellow-100: #f0ae00;
$mmc-yellow-075: #f4c240;
$mmc-yellow-050: #f7d780;
$mmc-yellow-025: #fdefce;
$mmc-yellow-010: #fdf7e5;

$mmc-green-100: #24b275;
$mmc-green-075: #5bc597;
$mmc-green-050: #91d8ba;
$mmc-green-025: #c8ecdd;
$mmc-green-010: #e9f7f1;

$mmc-soft-slate: #677788;
$mmc-soft-slate-50: #f3f4f5;
$mmc-accent-gray: #d9d9d9;
$mmc-gray: #787e8a;
$mmc-slate: #3f4659;
$mmc-slate-010: #ececee;
$mmc-soft-gray: #f7f8fa;
$mmc-disabled-gray: #b7b7b7;
$mmc-white: #ffffff;
$mmc-black: #000000;
$mmc-whisper-blue: #e6eaf4;

$mmc-violet-100: #951ce4;
$mmc-gold-100: #d8b500;
$mmc-silver-100: #cdcdcd;
$mmc-bronze-100: #ae9041;

$mmc-primary: #3063e4 !default;
$rmd-theme-primary: $mmc-primary;
