@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.ant-modal-footer) {
          background-color: colors.$mmc-soft-gray;
        }
      }
      .textField {
        & > *:first-child {
          color: colors.$mmc-soft-slate;
        }
      }
    }
  }
}
