@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        height: 3rem;
        align-items: center;
        font-weight: 500;
        padding: 0 1rem;
        justify-content: space-between;
        background: linear-gradient(
          95.84deg,
          rgba(200, 236, 221, 0.5) -12.93%,
          rgba(234, 239, 252, 0.5) 52.44%,
          rgba(253, 239, 206, 0.5) 101.93%
        );
      }

      .info {
        display: flex;
        flex-direction: column;
      }

      .title {
        display: flex;
        align-items: center;
        color: colors.$mmc-primary;
        gap: 0.5rem;
      }

      .enterIcon {
        color: colors.$mmc-primary-050;
      }

      .upgrade {
        color: colors.$mmc-soft-slate;
        text-decoration: underline;
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
    }
  }
}
