@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        background: colors.$mmc-white;
        border-radius: 0.375rem;
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        user-select: none;
      }

      .btn {
        background: none;
        border: none;
        font-weight: normal;
        height: auto;
        line-height: normal;
        margin: 0;
        padding: 0 0.5rem;

        & > span + :global(.fa-angle-down) {
          margin-left: 0.25rem;
          max-width: 0.6rem;
          vertical-align: middle;
        }

        &:global(.ant-dropdown-open) {
          color: colors.$mmc-primary-075;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      .add,
      .add > :global(.ant-btn) {
        background: none;
        border: none;
        outline: none;
        padding: 0;

        &:not(:disabled) {
          color: colors.$mmc-slate;
        }

        &:disabled {
          color: colors.$mmc-disabled-gray;
          &:hover {
            background: none;
          }
        }
      }

      .menu {
        background: none;
        border: none;
        box-shadow: none;
        padding: 0.25rem;

        :global(.ant-menu-item-divider) {
          background-color: colors.$mmc-accent-gray;
        }

        :global(.ant-menu-item) {
          align-items: center;
          border-radius: 0.25rem;
          display: flex;
          flex-direction: row;
          height: 2rem;
          justify-content: space-between;
          line-height: 2rem;
          margin: 0;

          .remove {
            display: none;
          }

          &:global(.ant-menu-item-active) {
            background-color: colors.$mmc-soft-gray;
            color: inherit;

            .remove {
              display: block;
            }
          }

          &:global(.ant-menu-item-selected) {
            background-color: colors.$mmc-primary-010;
            color: inherit;
            font-weight: bold;
          }
        }
      }

      .custom {
        align-items: center;
        border-top: 1px solid colors.$mmc-accent-gray;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        padding: 0.5rem;
      }

      .group {
        align-items: center;
        background: colors.$mmc-soft-gray;
        border-radius: 0.25rem;
        border: 1px solid colors.$mmc-accent-gray;
        display: flex;
        flex-direction: row;

        :global(.ant-input-number) {
          background: colors.$mmc-white;
          border-radius: 0.25rem 0 0 0.25rem;
          border-right: 1px solid colors.$mmc-accent-gray;
        }

        .unit {
          font-weight: 500;
          padding: 0 0.5rem;
        }
      }

      .error {
        color: colors.$mmc-red-100;
      }

      .tooltip {
        :global(.ant-tooltip-inner) {
          max-width: 10rem;
          text-align: center;
        }
      }

      .menuItem {
        & > :global(.ant-dropdown-menu-title-content) {
          display: flex;
          gap: 0.25rem;
        }
      }
      .remove {
        border: none;
        color: colors.$mmc-red-075;
        font-size: 0.5rem;
        height: auto;
        line-height: normal;
        margin: 0;
        padding: 0;
        width: auto;

        &:not(:disabled) {
          &:hover {
            color: colors.$mmc-red-100;
          }
        }

        & > svg {
          font-size: 0.75rem;
        }
      }
    }
  }
}
