@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .message {
        font-weight: 500;
      }
      .info {
        width: 22rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background-color: colors.$mmc-white;
        border-radius: 0.5rem;
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        padding: 0.75rem;
      }
      .criteriaDate {
        background-color: colors.$mmc-soft-gray;
        padding: 0.5rem;
        font-weight: 500;
      }
      .activityTypeIcon {
        width: 1rem;
        height: 1rem;
        margin-right: 0.25rem;
        & > svg {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
      .activityTypes {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        column-gap: 0.5rem;
      }
      .divider {
        line-height: 0.5rem;
      }
    }
  }
}
