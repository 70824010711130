@use "styles/colors";
@use "styles/layout";
@use "../styles/thumbnail";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        cursor: pointer;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        position: relative;

        &.videoContainer {
          padding-top: 0;
          padding-bottom: 0;
          flex-shrink: 0;
        }

        &:hover > .overlay {
          display: flex;
        }
      }

      .overlay {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: linear-gradient(
          180deg,
          colors.$mmc-slate,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0)
        );
        opacity: 0.75;
        justify-content: flex-end;
        align-items: flex-start;

        & .moreButton {
          color: colors.$mmc-white;
        }
      }

      .downloadIcon {
        color: colors.$mmc-primary;
        margin-right: 0.5rem;
      }

      .deleteIcon {
        color: colors.$mmc-red-100;
        margin-right: 0.5rem;
      }

      .image {
        width: 100%;
        object-fit: contain;
        pointer-events: none;
      }

      .video {
        width: thumbnail.$base-thumbnail-width;
        height: 5rem; // not using 100% to make it have typical video aspect ratio (~16:10)

        background-color: colors.$mmc-slate;
        display: flex;
        align-items: center;
        justify-content: center;
        color: colors.$mmc-white;
      }

      .loader {
        width: thumbnail.$base-thumbnail-width;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: colors.$mmc-soft-gray;
      }
    }
  }
}
