@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .entityIcon {
        color: colors.$mmc-white;
        width: 0.4rem;
      }
      .iconContainer {
        width: 1.25rem;
        height: 1.25rem;
        background-color: colors.$mmc-white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
      }
      .iconWrapper {
        width: 1rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
      }
      .accounts {
        background-color: colors.$mmc-primary-100;
      }
      .contacts {
        background-color: colors.$mmc-green-100;
      }
      .deals {
        background-color: colors.$mmc-red-100;
      }
      .entityName {
        font-weight: 500;
        font-size: 0.875rem;
      }
    }
  }
}
