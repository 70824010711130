body[class*="theme-"].theme .red_e974fa {
  color: #ffebee;
  background: #d50000;
}

body[class*="theme-"].theme .pink_e974fa {
  color: #fff;
  background: #d81b60;
}

body[class*="theme-"].theme .purple_e974fa {
  color: #e1bee7;
  background: #7b1fa2;
}

body[class*="theme-"].theme .deep-purple_e974fa {
  color: #d1c4e9;
  background: #311b92;
}

body[class*="theme-"].theme .indigo_e974fa {
  color: #c5cae9;
  background: #3949ab;
}

body[class*="theme-"].theme .blue_e974fa {
  color: #fff;
  background: #2962ff;
}

body[class*="theme-"].theme .light-blue_e974fa {
  color: #311b92;
  background: #4fc3f7;
}

body[class*="theme-"].theme .cyan_e974fa {
  color: #004d40;
  background: #26c6da;
}

body[class*="theme-"].theme .teal_e974fa {
  color: #004d40;
  background: #1de9b6;
}

body[class*="theme-"].theme .green_e974fa {
  color: #e8f5e9;
  background: #2e7d32;
}

body[class*="theme-"].theme .light-green_e974fa {
  color: #1b5e20;
  background: #aed581;
}

body[class*="theme-"].theme .lime_e974fa {
  color: #00695c;
  background: #d4e157;
}

body[class*="theme-"].theme .yellow_e974fa {
  color: #795548;
  background: #ff0;
}

body[class*="theme-"].theme .amber_e974fa {
  color: #4e342e;
  background: #ffca28;
}

body[class*="theme-"].theme .orange_e974fa {
  color: #212121;
  background: #fb8c00;
}

body[class*="theme-"].theme .deep-orange_e974fa {
  color: #212121;
  background: #ff3d00;
}

body[class*="theme-"].theme .brown_e974fa {
  color: #efebe9;
  background: #795548;
}

body[class*="theme-"].theme .grey_e974fa {
  color: #f5f5f5;
  background: #616161;
}

body[class*="theme-"].theme .blue-grey_e974fa {
  color: #eceff1;
  background: #455a64;
}

body[class*="theme-"].theme .selected-user-0_e974fa {
  color: #fff;
  background: #3063e4;
}

body[class*="theme-"].theme .selected-user-1_e974fa {
  color: #fff;
  background: #2e7d32;
}

body[class*="theme-"].theme .selected-user-2_e974fa {
  color: #fff;
  background: #7b1fa2;
}

body[class*="theme-"].theme .selected-user-3_e974fa {
  color: #fff;
  background: #dd2c00;
}

body[class*="theme-"].theme .selected-user-4_e974fa {
  color: #fff;
  background: teal;
}

body[class*="theme-"].theme .selected-user-5_e974fa {
  color: #fff;
  background: #d81b60;
}

body[class*="theme-"].theme .selected-user-6_e974fa {
  color: #fff;
  background: navy;
}

body[class*="theme-"].theme .selected-user-7_e974fa {
  color: #fff;
  background: #556b2f;
}

body[class*="theme-"].theme .selected-user-8_e974fa {
  color: #fff;
  background: #9237d9;
}

body[class*="theme-"].theme .selected-user-9_e974fa {
  color: #fff;
  background: #cb0303;
}

body[class*="theme-"].theme .selected-user-10_e974fa {
  color: #fff;
  background: #4e342e;
}

body[class*="theme-"].theme .selected-user-11_e974fa {
  color: #fff;
  background: #b549a2;
}

body[class*="theme-"].theme .selected-user-12_e974fa {
  color: #fff;
  background: #bf360c;
}

body[class*="theme-"].theme .selected-user-13_e974fa {
  color: #fff;
  background: #01579b;
}

body[class*="theme-"].theme .selected-user-14_e974fa {
  color: #fff;
  background: #827717;
}

body[class*="theme-"].theme .selected-user-15_e974fa {
  color: #fff;
  background: #880e4f;
}

body[class*="theme-"].theme .selected-user-16_e974fa {
  color: #fff;
  background: #006064;
}

body[class*="theme-"].theme .selected-user-17_e974fa {
  color: #fff;
  background: #795548;
}

body[class*="theme-"].theme .selected-user-18_e974fa {
  color: #fff;
  background: #aa133f;
}

body[class*="theme-"].theme .selected-user-19_e974fa {
  color: #fff;
  background: #546e7a;
}

body[class*="theme-"].theme .selected-user-20_e974fa {
  color: #fff;
  background: #263238;
}

body[class*="theme-"].theme .container_f83cb4 {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

body[class*="theme-"].theme .actionContainer_f83cb4 {
  background-color: #fff;
  width: 100%;
}

body[class*="theme-"].theme .toolbarPopover_f83cb4 .ant-popover-content .ant-popover-inner {
  border: none;
}

body[class*="theme-"].theme .toolbarPopover_f83cb4 .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 0;
}

body[class*="theme-"].theme .togglerButton_f83cb4 {
  cursor: pointer;
  padding: .25rem .5rem;
}

body[class*="theme-"].theme span.active_f83cb4 {
  color: #3063e4;
  background-color: #eaeffc;
}

body[class*="theme-"].theme .disabledIcon_f83cb4 {
  color: #b7b7b7;
}

body[class*="theme-"].theme .container_356e10 .ant-modal-header {
  border: none;
  padding-bottom: 0;
}

body[class*="theme-"].theme .container_356e10 .ant-modal-body {
  border: none;
  flex-direction: column;
  gap: .25rem;
  padding-top: .5rem;
  display: flex;
}

body[class*="theme-"].theme .container_356e10 .ant-modal-footer {
  border: none;
  padding: 0 1rem 1rem;
}

body[class*="theme-"].theme .preview_356e10 {
  cursor: pointer;
  color: #3063e4;
}

body[class*="theme-"].theme .label_356e10 {
  color: #677788;
}

body[class*="theme-"].theme .link_356e10 {
  width: 100%;
}

body[class*="theme-"].theme .container_eeb84e {
  background-color: #fff;
  gap: .25rem;
  width: fit-content;
  height: 2.5rem;
  padding: .25rem .5rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 12px #0000000f;
}

body[class*="theme-"].theme .extraMargin_eeb84e {
  position: initial;
  box-shadow: none;
  margin-bottom: .25rem;
  margin-left: .25rem;
}

body[class*="theme-"].theme .divider_eeb84e.ant-divider.ant-divider-vertical {
  border-left-color: #b7b7b7;
  height: 1.25rem;
  margin-top: .5rem;
}

body[class*="theme-"].theme .togglerButton_eeb84e {
  cursor: pointer;
  color: #677788;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-width: 2rem;
  height: 2rem;
  padding: 0 .125rem;
  display: flex;
}

body[class*="theme-"].theme .togglerButton_eeb84e:hover {
  background-color: #f7f8fa;
}

body[class*="theme-"].theme .togglerButton_eeb84e svg {
  height: 1rem;
}

body[class*="theme-"].theme .togglerButtonContent_eeb84e {
  width: max-content;
}

body[class*="theme-"].theme .dropDownChevron_eeb84e {
  width: .75rem;
}

body[class*="theme-"].theme .active_eeb84e {
  color: #3063e4;
  background-color: #eaeffc;
}

.aybP7G_container .ant-dropdown-menu-title-content {
  justify-content: center;
  display: flex;
}

body[class*="theme-"].theme .container_4ae8f2 {
  border: none;
  border-radius: .25rem;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: 100%;
  min-height: 0;
  display: flex;
  position: relative;
}

body[class*="theme-"].theme .container_4ae8f2:focus-within {
  box-shadow: 0 .25rem .75rem #0000000f;
}

body[class*="theme-"].theme .loading_4ae8f2 {
  opacity: .4;
  background-color: #fff9;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

body[class*="theme-"].theme .editorContainer_4ae8f2 {
  flex-direction: column;
  flex: 1;
  min-height: 0;
  display: flex;
}

body[class*="theme-"].theme .editorWrapper_4ae8f2 {
  flex-direction: column;
  flex: 1;
  min-height: 0;
  display: flex;
  overflow-y: auto;
}

body[class*="theme-"].theme .toolbar_4ae8f2 {
  align-items: center;
  gap: .25rem;
  padding: .25rem .5rem;
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  box-shadow: 0 .25rem .75rem #0000000f;
}

body[class*="theme-"].theme .sizeSmall_4ae8f2 {
  font-size: .75rem;
  line-height: 1.125rem;
}

body[class*="theme-"].theme .sizeMedium_4ae8f2 {
  font-size: .875rem;
  line-height: 1.25rem;
}

body[class*="theme-"].theme .sizeLarge_4ae8f2 {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

body[class*="theme-"].theme .editor_4ae8f2 {
  resize: none;
  border-radius: .25rem;
  flex: 1;
  width: 100%;
  min-height: 300px;
  padding: .75rem 0 .25rem;
  font-size: 1rem;
  line-height: 1rem;
}

body[class*="theme-"].theme .editor_4ae8f2 p {
  margin-bottom: 0;
}

body[class*="theme-"].theme .editor_4ae8f2[disabled], body[class*="theme-"].theme .editor_4ae8f2:disabled {
  color: #b7b7b7;
  background: #f5f5f5;
  outline: none;
}

body[class*="theme-"].theme .editor_4ae8f2[disabled] [data-slate-placeholder], body[class*="theme-"].theme .editor_4ae8f2:disabled [data-slate-placeholder] {
  opacity: 1 !important;
}

body[class*="theme-"].theme .extraPadding_4ae8f2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

body[class*="theme-"].theme .toolbarWrapper_4ae8f2 {
  margin-top: .5rem;
}

body[class*="theme-"].theme .modal_f55b26 .ant-modal-body {
  padding-top: 2rem;
}

body[class*="theme-"].theme .errorTitle_f55b26 {
  color: #3f4659;
  margin-bottom: .5rem;
}

body[class*="theme-"].theme .errorDescription_f55b26 {
  color: #3f4659;
  font-size: .875rem;
}

._5sUqbq_container {
  color: #3f4659;
  background-color: #fdefce;
  border-radius: .25rem;
  padding: .25rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.375rem;
}

._5sUqbq_allEntitiesHaveValues {
  color: #3063e4;
  background-color: #eaeffc;
}

body[class*="theme-"].theme .overlayContainer_9f943f {
  background-color: #f7f8fa;
  border-radius: .25rem;
  flex-direction: column;
  width: 28rem;
  height: 19rem;
  padding: 0 .5rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 1rem;
  box-shadow: 0 .25rem .75rem #00000026;
}

body[class*="theme-"].theme .overlaySearchContainer_9f943f {
  padding: .75rem 0;
}

body[class*="theme-"].theme .overlayList_9f943f {
  flex-direction: column;
  flex: 1;
  gap: 1px;
  height: 100%;
  min-height: 0;
  display: flex;
  overflow-y: auto;
}

body[class*="theme-"].theme .overlayItem_9f943f {
  cursor: pointer;
  background-color: #fff;
  padding: .5rem 0;
}

body[class*="theme-"].theme .overlayItem_9f943f:hover {
  background-color: #f7f8fa;
}

body[class*="theme-"].theme .overlayItemValue_9f943f {
  color: #677788;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

body[class*="theme-"].theme .noResult_9f943f {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  height: 100%;
  min-height: 0;
  display: flex;
}

body[class*="theme-"].theme .noResultTitle_9f943f {
  color: #3f4659;
  font-size: 1rem;
  font-weight: 500;
}

body[class*="theme-"].theme .noResultSubTitle_9f943f {
  color: #677788;
  font-size: .875rem;
}

body[class*="theme-"].theme .container_f7e287 {
  color: #fff;
  background: #677788;
}

body[class*="theme-"].theme .audio_f7e287 {
  background: #f0ae00;
}

body[class*="theme-"].theme .video_f7e287 {
  background: #951ce4;
}

body[class*="theme-"].theme .image_f7e287 {
  background: #91d8ba;
}

body[class*="theme-"].theme .pdf_f7e287 {
  background: #ff5858;
}

body[class*="theme-"].theme .spreadsheet_f7e287 {
  background: #24b275;
}

body[class*="theme-"].theme .document_f7e287 {
  background: #3063e4;
}

body[class*="theme-"].theme .container_6438a2 {
  flex-wrap: wrap;
  gap: .5rem 1rem;
  display: flex;
}

body[class*="theme-"].theme .fileContainer_6438a2 {
  color: #677788;
  background-color: #f7f8fa;
  border-radius: .5rem;
  align-items: center;
  gap: .5rem;
  width: calc(50% - 1rem);
  padding: .5rem;
  display: flex;
}

body[class*="theme-"].theme .mainFileContainer_6438a2 {
  flex: 1;
  align-items: center;
  display: flex;
}

body[class*="theme-"].theme .clicable_6438a2 {
  cursor: pointer;
}

body[class*="theme-"].theme .fileIcon_6438a2 {
  width: 1.25rem;
  min-width: 0;
  height: 1.25rem;
  line-height: .75rem;
}

body[class*="theme-"].theme .fileIcon_6438a2 svg {
  height: .625rem;
}

body[class*="theme-"].theme .downloadIcon_6438a2 {
  color: #3063e4;
  cursor: pointer;
}

body[class*="theme-"].theme .deleteIcon_6438a2 {
  cursor: pointer;
}

body[class*="theme-"].theme .divider_6438a2 {
  border-color: #ececee;
  margin: 0;
}

body[class*="theme-"].theme .fileName_6438a2 {
  flex: 1;
  align-items: center;
  min-width: 0;
  display: flex;
}

body[class*="theme-"].theme .fileNamePure_6438a2 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

body[class*="theme-"].theme .fileNameExtra_6438a2 {
  align-items: center;
  gap: .25rem;
  display: flex;
}

body[class*="theme-"].theme .actions_6438a2 {
  align-items: center;
  gap: 1rem;
  min-width: 0;
  display: flex;
}

.J0nttG_popconfirmContainer {
  width: 16rem;
}

.J0nttG_popconfirmTitle {
  color: #3f4659;
  margin-bottom: .5rem;
  font-weight: 700;
}

body[class*="theme-"].theme .container_23bb06 {
  width: fit-content;
  position: relative;
}

body[class*="theme-"].theme .image_23bb06 {
  pointer-events: none;
  display: block;
}

body[class*="theme-"].theme .delete_23bb06 {
  position: absolute;
  top: .5em;
  left: .5em;
}

body[class*="theme-"].theme .resizer_23bb06 {
  border: 2px solid #3063e4;
  position: absolute;
}

body[class*="theme-"].theme .resizeControl_23bb06 {
  background-color: #3063e4;
  border: 2px solid #fff;
  width: .5rem;
  height: .5rem;
  position: absolute;
}

body[class*="theme-"].theme .toolbar_23bb06 {
  border-radius: .25rem;
  gap: .5rem;
  width: fit-content;
  padding: .5rem;
  display: flex;
  box-shadow: 0 .25rem .75rem #00000026;
}

body[class*="theme-"].theme .divider_23bb06 {
  background-color: #677788;
  width: 1px;
  height: 1rem;
}

body[class*="theme-"].theme .control_23bb06 {
  font-weight: 500;
}

body[class*="theme-"].theme .control_23bb06:disabled {
  color: #3f4659;
}

body[class*="theme-"].theme .removeControl_23bb06 {
  color: #ff5858;
}

body[class*="theme-"].theme .altModalContent_23bb06 {
  padding-top: .5rem;
}

body[class*="theme-"].theme .altModalCaption_23bb06 {
  color: #677788;
}

body[class*="theme-"].theme .popover_2b8b88 .ant-popover-inner {
  border: 0;
}

body[class*="theme-"].theme .popover_2b8b88 .ant-popover-inner-content {
  border-radius: .25rem;
  padding: 0 .5rem;
  box-shadow: 0 .25rem .75rem #00000026;
}

body[class*="theme-"].theme .divider_2b8b88.ant-divider.ant-divider-vertical {
  border-color: #b7b7b7;
  height: 1.25rem;
  margin: .5rem;
}

body[class*="theme-"].theme .link_2b8b88 {
  color: #3063e4;
  padding: 0;
  font-weight: bold;
}

body[class*="theme-"].theme .button_2b8b88 {
  padding: 0;
}

body[class*="theme-"].theme .url_2b8b88 {
  margin-left: .5rem;
}

body[class*="theme-"].theme .goToLink_2b8b88 {
  color: #677788;
}

body[class*="theme-"].theme .enterManuallyButton_da709c {
  text-align: center;
  width: 100%;
  display: block;
}

body[class*="theme-"].theme .noResults_da709c {
  text-align: center;
  width: 100%;
}

body[class*="theme-"].theme .markerIcon_da709c {
  color: #3063e4;
}

body[class*="theme-"].theme .placeOption_da709c {
  line-height: 30px;
}

body[class*="theme-"].theme .placeOption_da709c:hover {
  background: #f7f8fa;
}

body[class*="theme-"].theme .placeDescription_da709c {
  color: #677788;
  margin-left: 8px;
}

body[class*="theme-"].theme .container_3bc5df {
  gap: .5rem;
  width: 100%;
  min-width: 0;
  padding: .25rem 0;
  display: flex;
  overflow: auto;
}

body[class*="theme-"].theme .recipients_3bc5df {
  flex: 1;
  min-width: 0;
  display: flex;
  overflow: hidden;
}

body[class*="theme-"].theme .extendedContainer_3bc5df {
  flex-wrap: wrap;
  flex: 1;
  gap: .5rem;
  width: 100%;
  min-width: 0;
  display: flex;
}

body[class*="theme-"].theme .email_3bc5df {
  white-space: nowrap;
  align-items: center;
  gap: .25rem;
  display: flex;
}

body[class*="theme-"].theme .inlineEmail_3bc5df {
  border-bottom: 1px solid #3063e4;
  margin-right: .5rem;
  padding: .25rem .4rem;
  font-size: .75rem;
  font-weight: 400;
}

body[class*="theme-"].theme .inlineEmailDuplicate_3bc5df {
  background: #fdf7e5;
  border-bottom: 1px solid #f0ae00;
}

body[class*="theme-"].theme .normalEmail_3bc5df {
  background: #f7f8fa;
  border: 1px solid #d9d9d9;
  border-radius: .25rem;
  align-items: center;
  gap: .25rem;
  padding: 0 .25rem;
  font-size: .75rem;
  display: flex;
}

body[class*="theme-"].theme .normalEmailDuplicate_3bc5df {
  background: #fdf7e5;
  border: 1px solid #f7d780;
}

body[class*="theme-"].theme .more_3bc5df {
  cursor: pointer;
  background-color: #eaeffc;
  border: 1px solid #3063e4;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  height: 100%;
  padding: 0 .4rem;
  display: flex;
}

body[class*="theme-"].theme .warningIcon_3bc5df {
  color: #f0ae00;
}

body[class*="theme-"].theme .moreWithDuplicates_3bc5df {
  background-color: #fdf7e5;
  border: 1px solid #f7d780;
}

body[class*="theme-"].theme .deleteIcon_3bc5df {
  cursor: pointer;
}

body[class*="theme-"].theme .issuePopconfirm_3bc5df {
  width: 16rem;
}

body[class*="theme-"].theme .recipientName_3bc5df {
  flex: 1;
}

body[class*="theme-"].theme .warningPoints_3bc5df {
  padding-left: 2rem;
}

body[class*="theme-"].theme .container_967202 .mmc-text-field__input .ant-input {
  border: none;
  width: 100%;
  padding: .5rem 0;
}

body[class*="theme-"].theme .container_967202 .mmc-text-field__input .ant-input:focus {
  box-shadow: none;
  border: none;
}
