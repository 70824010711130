@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        user-select: none;
      }

      .itemContainer {
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      .item {
        display: block;
        cursor: pointer;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        transition: transform 100ms ease-in;
        border: 1px solid rgba(0, 0, 0, 0.1);
        &.inactive {
          cursor: default;
        }
        &:not(.inactive):hover {
          transform: scale(1.25);
        }
      }

      .selected {
        transform: scale(1.25);
      }

      .currentColor {
        margin-left: 0.25rem;
        color: rgba(0, 0, 0, 0.5);
      }

      .noColor {
        background: linear-gradient(
          to top left,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) calc(50% - 0.8px),
          colors.$mmc-disabled-gray 50%,
          rgba(0, 0, 0, 0) calc(50% + 0.8px),
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
}
