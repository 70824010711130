@import "../../styles/layout";
@import "../../styles/colors";
@import "~@react-md/transition/dist/variables";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        background: transparent;
        width: 100vw;
        height: 100%;
        // Fix for mobile safari which does include space behind browser's bottom toolbar
        // into 100vh. And thus a bottom part of the web page is not visible.
        // More info: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
        min-height: 100vh;
        min-height: -webkit-fill-available;
      }

      .content {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
      }

      .contentWrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include rmd-theme(background-color, background);
        transition: all $rmd-transition-standard-time * 2 cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }
}
