@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        margin: 0;
        padding: 0;
        border: 0;
        height: auto;

        &[data-disabled] {
          color: colors.$mmc-disabled-gray;
        }
      }
    }
  }
}
