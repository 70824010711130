@use "styles/colors";
@use "../styles/thumbnail";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .gallery {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

        %button {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          &[disabled],
          &:disabled {
            color: colors.$mmc-disabled-gray;
            background-color: colors.$mmc-soft-gray;
          }
        }

        & > .prevButton {
          @extend %button;
          justify-self: flex-start;
        }

        & > .nextButton {
          @extend %button;
          justify-self: flex-end;
        }
      }

      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .pages {
        position: relative;
        height: 8rem;
        overflow: hidden;

        & > .thumbnails {
          transition-property: left, right;
          transition-duration: 0.5s;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          position: absolute;
          display: inline-flex;
          justify-content: center;
          gap: 0.25rem;
          align-items: center;
          height: 100%;

          & > .thumbnail {
            height: 100%;
            flex-basis: auto;
            max-width: min(#{thumbnail.$base-thumbnail-width * 2}, 40%);
          }
        }
      }

      .smallPages {
        height: 5rem;
      }
    }
  }
}
