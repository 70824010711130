@use "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        cursor: pointer;
        height: 0.875rem;
        left: var(--point-pos);
        width: var(--point-width);
        opacity: 0;
        position: absolute;
        &:hover {
          opacity: 1;
        }
        display: flex;
        justify-content: center;
      }
      .point {
        min-width: 0.875rem;
        height: 0.875rem;
        border-radius: 50%;
        border: 2px colors.$mmc-disabled-gray solid;
        background-color: colors.$mmc-white;
        z-index: 3;
      }
      .tooltip {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
      }
      .tooltipTitle {
        font-weight: 700;
        font-size: 0.875rem;
      }
      .tooltipSubTitle {
        font-weight: 400;
        font-size: 0.875rem;
      }
      .outSoon {
        border-color: colors.$mmc-yellow-100;
      }
      .overdue {
        border-color: colors.$mmc-red-100;
      }
      .upToDate {
        border-color: colors.$mmc-green-100;
      }
    }
  }
}
