:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 12.5rem auto;
        row-gap: 0.625rem;
      }
    }
  }
}
