:global {
  body[class*="theme-"].theme {
    :local {
      .returnLink {
        margin-top: 1rem;
        display: block;
        width: 100%;
      }

      .returnButton {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
