@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding: 0.625rem 1rem;
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
      }
      .selected {
        background-color: colors.$mmc-primary-005;
      }
      .name {
        color: colors.$mmc-slate;
        font-weight: 500;
      }

      .icon {
        width: 1.5rem;
        height: 1.5rem;
        font-size: 0.5rem;
        & > svg {
          width: 0.8rem;
          height: 0.8rem;
          color: colors.$mmc-white;
        }
      }
      .queryIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #ececee;
        & > svg {
          color: colors.$mmc-soft-slate;
        }
      }
      .blackIcon {
        & > svg {
          color: colors.$mmc-slate;
        }
      }
      .enterIcon {
        color: colors.$mmc-primary-025;
      }
      .mainPartWrapper {
        display: flex;
        width: 100%;
        gap: 1rem;
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
