@import "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        padding: 1rem;
      }

      .text {
        font-size: 0.75rem;
        font-weight: 500;
        color: $mmc-soft-slate;
      }
    }
  }
}
