:global {
  body[class*="theme-"].theme {
    :local {
      .previewModal {
        & :global(.ant-modal-title) {
          margin-right: 1rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:global(.ant-modal-wrap-rtl) :global(.ant-modal-title) {
          margin-right: 0;
        }
        &:global(.ant-modal-wrap) {
          z-index: 1050;
        }
      }

      .preview {
        display: flex;
        justify-content: center;
        align-items: center;

        & :global(.react-pdf__Document) {
          max-width: 100%;
        }
      }

      .previewImage {
        max-width: 100%;
        max-height: 50vh;
      }
    }
  }
}
