@use "styles/colors";
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .content {
        padding: 0.25rem 1rem 1rem 1.5rem;
        display: flex;
        align-items: flex-start;
        gap: 1rem;
      }

      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.125rem;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
      }

      .name {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 500;
        color: colors.$mmc-slate;
      }

      .chips {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .chip {
        & > div {
          font-size: 0.875rem;
        }
      }

      .chipHover {
        &:hover {
          color: colors.$mmc-soft-slate !important; // To override antd color
        }
      }

      .imageContainer {
        margin-top: -1rem;
        flex-shrink: 0;
        height: 4.375rem;
        width: 4.375rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
        background: colors.$mmc-soft-gray;
        border: 3px solid colors.$mmc-white;
        box-shadow: 0 0.25rem 0.75rem #{color.change(colors.$mmc-black, $alpha: 0.06)};

        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        text-align: center;
        font-size: 0.75rem;
        color: colors.$mmc-soft-slate;

        & > * {
          border-radius: 0.25rem;
        }
      }

      .imageHover {
        &:hover {
          border-color: colors.$mmc-slate-010;
        }
      }

      .nameHover {
        &:hover {
          color: colors.$mmc-soft-slate;
        }
      }

      .noImage {
        color: colors.$mmc-soft-slate;
        font-size: 2.5rem;
      }

      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
}
