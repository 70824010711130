// These are some generic SCSS variables. Consider them constants.
// CSS variables are defined in themes, see themes folder.

// define number variables to import them in TS files. Used in ./_layout.module.scss

$spacer-xs-value: 4;
$spacer-s-value: 8;
$spacer-m-value: 16;
$spacer-l-value: 24;
$spacer-xl-value: 32;

$spacer-xs: $spacer-xs-value + 0px;
$spacer-s: $spacer-s-value + 0px;
$spacer-m: $spacer-m-value + 0px;
$spacer-l: $spacer-l-value + 0px;
$spacer-xl: $spacer-xl-value + 0px;

$breakpoint-wide-desktop-value: 1530;
$breakpoint-desktop-value: 1340;
$breakpoint-tablet-value: 920;
$breakpoint-tablet-portrait-value: 500;
$breakpoint-mobile-value: 500;

$breakpoint-wide-desktop: $breakpoint-wide-desktop-value + 0px;
$breakpoint-desktop: $breakpoint-desktop-value + 0px;
$breakpoint-tablet: $breakpoint-tablet-value + 0px;
$breakpoint-tablet-portrait: $breakpoint-tablet-portrait-value + 0px;
$breakpoint-mobile: $breakpoint-mobile-value + px;
