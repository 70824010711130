:global {
  body[class*="theme-"].theme {
    :local {
      .fullWidth {
        width: 100%;
      }
      .noPadding {
        padding: 0;
      }
    }
  }
}
