@use "styles/colors";
@use "styles/layout";
@use "../../../../util/colors/userColor/userColors" as userColors;
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: inline-flex;
        align-items: center;
        padding: 1px 0.5rem;
        margin: 0.125rem 0.25rem 0.125rem 0;
      }
      @each $suffix, $colors in userColors.$selected-user-colors {
        .#{$suffix}:global(.ant-tag-custom-color) {
          background-color: nth($colors, 2);
          border-color: nth($colors, 1);
          color: nth($colors, 4);
          font-weight: 400;
          svg {
            color: nth($colors, 1);
          }
        }
      }

      .largeField {
        height: 2rem;
        font-size: 1rem;
      }
    }
  }
}
