@use "../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        height: 2.375rem;
        border: colors.$mmc-accent-gray 1px solid;
        border-radius: 0.125rem;
        width: 100%;
      }
      .leftContainer {
        height: 100%;
        display: flex;
      }
      .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.75rem;
        height: 100%;
      }
      .customIconContainer {
        background-color: colors.$mmc-soft-gray;
      }
      .content {
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        color: colors.$mmc-slate;
      }
      .faded {
        color: colors.$mmc-disabled-gray;
        font-style: italic;
      }
      .lockIcon {
        color: colors.$mmc-disabled-gray;
      }
    }
  }
}
