@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .activityScheduledIcon {
        color: colors.$mmc-green-100;
      }
    }
  }
}
