:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
      }

      .dot {
        border-radius: 50%;
        width: 0.75rem;
        height: 0.75rem;
        font-size: 0;
      }
    }
  }
}
