@use "styles/layout";
@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .stepContainer {
        display: block;
        height: auto;
        opacity: 1;
        transition: opacity 1s ease-in-out;
      }

      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 2rem;
      }

      .verticalButtons {
        flex-direction: column;
        gap: 1rem;
      }

      .submitButton {
        width: 100%;
      }

      .tncField {
        margin-bottom: 0;
        & :global(.ant-form-item-explain) {
          margin-bottom: 1rem;
        }
      }

      .backToLoginButton {
        margin-top: 1rem;
        text-align: center;
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
      }

      .tooltipIcon {
        @include colors.rmd-theme(color, primary);
      }

      .radioButton {
        display: block;
        margin-bottom: layout.$spacer-xs;
      }

      .checkboxWrapper {
        display: inline-flex;
        align-items: flex-start;
        & :global(.ant-checkbox) + span {
          margin-top: -0.1875rem;
        }
      }
    }
  }
}
