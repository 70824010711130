:global {
  body[class*="theme-"].theme {
    :local {
      .button {
        color: rgba(0, 0, 0, 0.54);
        flex: 0 0 2rem;
      }
    }
  }
}
