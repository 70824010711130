@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .alert {
        :global(.ant-alert-content) {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .title {
            align-items: center;
            display: flex;
            flex-direction: row;
            font-weight: 500;
            gap: 0.5rem;
          }

          .icon {
            color: colors.$mmc-red-100;
          }
        }
      }
    }
  }
}
