@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        width: calc(max(25%, 25rem));

        @media screen and (max-width: layout.$breakpoint-mobile) {
          min-width: calc(100% - 2rem);
          max-width: calc(100% - 2rem);
        }
      }

      .title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      .description {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }

      .input {
        :global(.ant-input-group-addon) {
          color: colors.$mmc-soft-slate;
        }
      }
    }
  }
}
