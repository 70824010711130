@import "../../styles/colors";
@import "../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .createLink {
        margin-top: $spacer-s;
        padding-left: 0px;
      }
    }
  }
}
