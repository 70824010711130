:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        span {
          white-space: nowrap;
          min-width: max-content;
        }
      }
    }
  }
}
