@import-normalize;

// Note: antd styles are loaded in theme/*.theme.scss files

@import "colors";
@import "antd";
@import "aggrid";
@import "global";
@import "animations";

$roboto-font-path: "../../roboto-fontface/fonts";
@import "~roboto-fontface/css/roboto/sass/roboto-fontface-regular.scss";
@import "~roboto-fontface/css/roboto/sass/roboto-fontface-medium.scss";
@import "~roboto-fontface/css/roboto/sass/roboto-fontface-bold.scss";

html {
  font-size: 16px !important; // size of 1rem
  font-family: "Twemoji Country Flags", Roboto, sans-serif;

  // Fix for mobile safari which does include space behind browser's bottom toolbar
  // into 100vh. And thus a bottom part of the web page is not visible.
  // More info: https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
  height: -webkit-fill-available;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

#root {
  height: 100%;
}
