:global {
  body[class*="theme-"].theme {
    :local {
      .entityIcon {
        width: 1.25rem;
        height: 1.25rem;
        font-size: 0.625rem;
      }
    }
  }
}
