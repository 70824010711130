@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .mention {
        background-color: colors.$mmc-soft-gray;
        color: colors.$mmc-primary;
        padding: 0.25rem 0.5rem;
        border-radius: 0.75rem;
        font-weight: 500;
      }
      .mentionDisabled {
        color: colors.$mmc-disabled-gray;
      }
      .mentionOverlay {
        & :global(.ant-tooltip-content) {
          & :global(.ant-tooltip-inner) {
            white-space: nowrap;
            width: fit-content;
          }
        }
      }
    }
  }
}
