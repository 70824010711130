@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 1rem;
        padding-top: 0.5rem;
        overflow: hidden;
      }

      .searchBar {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        .searchIcon {
          color: colors.$mmc-gray;
        }
      }

      .searchField {
        width: 100%;
      }

      .sortOrder {
        color: colors.$mmc-disabled-gray;
        flex-shrink: 0;
        font-weight: 500;
        text-transform: uppercase;
      }

      .listContainer {
        flex-grow: 1;
        margin: -0.25rem 0 0 -0.25rem;
        padding: 0.25rem 0 0 0.25rem;
        overflow: auto;
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .item {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.06);
        border-radius: 0.25rem;
        border-left: 0.25rem solid red;
        padding: 1rem;
        position: relative;
      }

      .cantFind {
        color: colors.$mmc-soft-slate;
        font-size: 0.75rem;
      }

      .showAll {
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
      }
    }
  }
}
