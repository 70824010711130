@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        border: none;
        font-size: 0.75rem;
        font-weight: 400;
        border-radius: 0.25rem;
        padding: 0.1rem 0.375rem;
        height: 1.375rem;
        user-select: none;
        &:not([class^="ant-tag-"]):not([class*=" ant-tag-"]) {
          background-color: colors.$mmc-white;
        }
      }

      .inline {
        display: inline-flex;
        margin-right: 0;
      }

      .noPadding {
        padding: 0;
      }

      .pointer {
        cursor: pointer;
      }

      .label {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.06);
        font-weight: 500;
      }
      .closable {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.06);
        font-weight: 500;
        border: 1px solid colors.$mmc-accent-gray;
      }

      span.disabled {
        &:not([class^="ant-tag-"]):not([class*=" ant-tag-"]) {
          background-color: colors.$mmc-soft-gray;
          color: colors.$mmc-disabled-gray;
        }
        cursor: not-allowed;
      }
    }
  }
}
