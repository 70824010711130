@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .alert {
        margin-top: 1.5rem;
        border-top: 0.5rem solid colors.$mmc-primary-100;
        border-radius: 0.5rem;
        :global(.ant-modal-body) {
          padding: 2rem 2rem 1.5rem 2rem;
        }
      }
    }
  }
}
