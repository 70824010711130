@import "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .resetButton {
        width: 100%;
      }

      .backToLoginLink {
        font-size: 0.875rem;
        text-align: center;
      }
    }
  }
}
