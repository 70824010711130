@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        background-color: colors.$mmc-white;
        width: 22rem;
      }
      .shadowed {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.06);
      }
      .title {
        font-weight: 500;
        padding: 0.5rem 0.75rem;
      }
      .entityContainer {
        display: flex;
        gap: 0.5rem;
      }
      .entityName {
        font-weight: 500;
      }
      .accounts {
        color: colors.$mmc-primary-100;
      }
      .contacts {
        color: colors.$mmc-green-100;
      }
      .deals {
        color: colors.$mmc-red-100;
      }
      .previewWrapper {
        padding: 0 1rem;
      }
    }
  }
}
