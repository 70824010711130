@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .modal {
        & :global(.ant-modal-header) {
          background-color: colors.$mmc-soft-gray;
        }
        & :global(.ant-modal-body) {
          height: 70vh;
          display: flex;
          flex-direction: column;
          padding: 0;
          min-height: 0;
        }
      }
      .container {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        flex: 1;
        padding: 0 1.5rem;
        min-height: 0;
      }
      .templateMenu {
        :global(.ant-dropdown-menu-title-content) {
          color: colors.$mmc-soft-slate;
        }
      }
      .templateList {
        height: 100%;
        overflow-y: auto;
        min-height: 0;
        min-width: 15rem;
        border-right: 1px solid colors.$mmc-soft-gray;
      }
      .footer {
        background-color: colors.$mmc-soft-gray;
        padding: 1rem;
      }
      .template {
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        cursor: pointer;
        width: 100%;
        &:hover {
          background-color: colors.$mmc-primary-010;
        }
      }
      .templatePreview {
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .templateHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .templateBody {
        flex: 1;
        min-height: 0;
      }
      .templateSelected {
        color: colors.$mmc-primary-100;
        background-color: colors.$mmc-primary-010;
      }
      .templateName {
        font-size: 1.25rem;
        font-weight: 600;
        padding: 0.5rem;
        border-radius: 0.25rem;
        width: fit-content;
        &:hover {
          background-color: colors.$mmc-soft-gray;
        }
        cursor: pointer;
      }
      .templateNameEdit {
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem;
        gap: 0.5rem;
      }
      .templateNameEditField {
        flex: 1;
        :global(.ant-input) {
          border: 0;
          outline: 0;
          font-size: 1.25rem;
          font-weight: 600;
        }
      }
      .confirmation {
        width: 16rem;
      }
      .moreIcon {
        cursor: pointer;
      }
    }
  }
}
