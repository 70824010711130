@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding: 0;

        & > :global(.ant-popover-content) {
          & > :global(.ant-popover-arrow) {
            display: none;
          }
          & > :global(.ant-popover-inner) > :global(.ant-popover-inner-content) {
            padding: 0;
          }
        }
      }
      .iconContainer {
        cursor: pointer;
      }
      .icon {
        color: colors.$mmc-slate;
        &.visible {
          color: colors.$mmc-soft-slate;
        }
      }
    }
  }
}
