@use "../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        background-color: colors.$mmc-soft-gray;
        padding: 0.5rem;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .title {
        color: colors.$mmc-slate;
        font-weight: 700;
        font-size: 0.75rem;
        display: flex;
        gap: 0.25rem;
        align-items: center;
      }
      .subTitle {
        color: colors.$mmc-soft-slate;
        font-size: 0.75rem;
      }
    }
  }
}
