@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        height: 30.25rem;
      }

      .image {
        width: 11rem;
      }

      .title {
        color: colors.$mmc-slate;
        font-size: 1.185rem;
        text-align: center;
        font-weight: 500;
      }

      .description {
        color: colors.$mmc-soft-slate;
        font-size: 1rem;
        text-align: center;
        font-weight: 500;
        max-width: 17.5rem;
      }
    }
  }
}
