@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        width: fit-content;
      }
      .activityText {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        font-style: italic;
        font-weight: 400;
        white-space: nowrap;
      }
      .avatar {
        width: 1rem;
        height: 1rem;
        font-size: 0.5rem;
        line-height: 1rem;
        & > svg {
          width: 0.6rem;
          height: 0.6rem;
        }
      }
    }
  }
}
