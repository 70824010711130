:global {
  body[class*="theme-"].theme {
    :local {
      .addressLabel {
        & > div:last-child > div {
          width: 100%;
        }
      }

      .maxWidth {
        width: 100%;
      }
    }
  }
}
