@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: layout.$spacer-xs;
      }

      .otherFile {
        margin-top: 1rem;
      }
    }
  }
}
