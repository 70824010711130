@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .alert {
        margin-top: 5rem;
        :global(.ant-modal-content) {
          width: 27rem;
        }
        :global(.ant-modal-body) {
          padding: 2rem 2rem 1.5rem 2rem;
        }
        :global(.anticon) {
          color: colors.$mmc-yellow-100;
        }
        :global(.ant-modal-confirm-content) {
          margin-top: 1rem;
        }
        :global(.ant-modal-confirm-title) {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}
