@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .popover {
        display: flex;
        align-self: center;
        justify-content: center;
        gap: 1rem;
        width: 27rem;
      }
      .title {
        font-weight: 500;
      }
      .text {
        margin: 1rem 0;
      }
      .link {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
    }
  }
}
