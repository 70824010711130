@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .tag {
        color: colors.$mmc-gray;
        border: 1px solid colors.$mmc-primary-025;
        font-size: 0.875rem;
        padding-bottom: 0.125rem;
      }
      .largeField {
        height: 2rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin: 0.125rem;
      }
    }
  }
}
