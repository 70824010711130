@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex: 1;
        overflow: hidden;
      }
      .headerIcon {
        font-size: 1.25rem;
        color: colors.$mmc-soft-slate;
        margin-left: 1rem;
        cursor: pointer;
      }
      .subTitle {
        font-size: 0.875rem;
        font-weight: 400;
        color: colors.$mmc-soft-slate;
      }
      .modal {
        z-index: 1035; // we need to cover at lease activity annotation modal
        :global(.ant-modal-body) {
          padding: 0 1rem 1rem;
          height: 100%;
          min-height: 0;
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        & :global(.ant-modal-content) {
          height: 80vh;
          display: flex;
          flex-direction: column;
        }
      }
      .divider {
        margin: 0;
        border-color: colors.$mmc-primary-010;
      }
      .confirmation {
        width: 16rem;
      }
    }
  }
}
