@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .unitCounter {
        width: 100%;
        flex: 1;
      }

      .unitSelector {
        width: 100%;
        flex: 1;
      }

      .suffix {
        flex-shrink: 0;
        text-transform: uppercase;
        color: colors.$mmc-disabled-gray;
        font-weight: 500;
      }
    }
  }
}
