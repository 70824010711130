@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        gap: 0.5rem;
      }

      .mainPart {
        color: colors.$mmc-slate;
        font-weight: 500;
        white-space: nowrap;
      }

      .secondaryPart {
        flex: 1;
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
      }

      .userAvatar {
        width: 1rem;
        height: 1rem;
        font-size: 0.75rem;
        line-height: 1rem;
      }

      .activityDone {
        color: colors.$mmc-green-100;
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-weight: 500;
      }

      .territoryUsers {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .cadenceValue {
        color: colors.$mmc-red-100;
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      .partsSeparator {
        margin: 0 0.25rem;
      }

      .primaryCompany {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      .star {
        color: colors.$mmc-yellow-100;
      }
    }
  }
}
