:global {
  body[class*="theme-"].theme {
    :local {
      .chip {
        & > div {
          font-size: 0.875rem;
        }
      }
    }
  }
}
