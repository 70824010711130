@import "../../../../styles/theme/typeface";
@import "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .errorMessage {
        margin-bottom: 1rem;
      }

      .forgotPasswordLink {
        font-size: 0.75rem;
        text-align: right;
        display: block;
        margin: -1rem 0 1rem auto;
        width: fit-content;
      }

      .loginButton {
        margin-top: 1rem;
      }

      .divider {
        :global(.ant-divider) {
          border-color: $mmc-accent-gray;
        }
        :global(.ant-divider-inner-text) {
          color: $mmc-soft-slate;
          font-size: 0.875rem;
          font-weight: 400;
        }
      }

      .container {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .inquire {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }
}
