@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .topSection {
        padding-top: 1rem;
        width: calc(100% - 2rem); // to avoid intersecting with modal close button
      }

      .modalWrapper {
        overflow: hidden;
        z-index: 1030;
      }

      .details {
        overflow: auto;
        margin-bottom: 0.5rem;
        max-height: 45vh;
        display: flex;
        flex-direction: column;
      }

      .modal {
        & :global(.ant-modal-body) {
          padding: 1.25rem;
          padding-top: 0;
        }
      }

      .paragraph {
        overflow: auto;
      }
    }
  }
}
