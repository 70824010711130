:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        margin-bottom: 0;
      }

      .noMargin {
        margin-bottom: 0;
      }

      .paragraph {
        margin-bottom: 0;
      }

      .collapseButton {
        padding: 0;
        margin: 0;
        border: 0;
        font-weight: normal;
        height: auto;
      }
    }
  }
}
