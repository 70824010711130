@use "../../../../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .notificationLoader {
        color: colors.$mmc-primary-100;
      }
    }
  }
}
