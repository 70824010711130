@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .viewAllDealsLink {
        margin-bottom: 1rem;
      }
    }
  }
}
