@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
      .dropdown {
        :global(.ant-dropdown-menu) {
          max-height: 50vh;
          overflow-y: auto;
        }
      }
      .updateConfirmation {
        width: 16rem;
      }
      .buttonContainer {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      .button {
        padding: 0.25rem 1rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: colors.$mmc-soft-slate;
        cursor: pointer;
        background-color: colors.$mmc-soft-gray;
        border-radius: 0.25rem;
        font-weight: 500;
      }
      .buttonWithTemplate {
        background-color: colors.$mmc-primary-010;
        color: colors.$mmc-primary-100;
        border-radius: 0.25rem;
        font-weight: 500;
      }
      .buttonWithTemplate {
        background-color: colors.$mmc-primary-010;
        color: colors.$mmc-primary-100;
      }
      .buttonWithTemplateHasChanges {
        background-color: colors.$mmc-yellow-010;
        color: colors.$mmc-slate;
      }
      .templateHasChangedIcon {
        color: colors.$mmc-yellow-100;
      }

      .menuItem {
        display: flex;
        gap: 0.25rem;
        align-items: center;
      }
      .menuItemIcon {
        width: 1rem;
      }
      .firstItemContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
      }
      .mostRecent {
        font-size: 0.75rem;
        color: colors.$mmc-soft-slate;
        text-transform: uppercase;
      }
      .selected {
        background-color: colors.$mmc-primary-010;
        border-radius: 0.25rem;
        color: colors.$mmc-primary-100;
      }
    }
  }
}
