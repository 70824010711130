@use "../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem;
        margin-bottom: 1rem;
        height: 100%;
        overflow-y: auto;
      }
    }
  }
}
