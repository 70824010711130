:global {
  body[class*="theme-"].theme {
    :local {
      .icon {
        // shift find me icon a bit to make it look more centered
        padding: 1px 1px 0 0;
      }
    }
  }
}
