@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        font-size: 0.875rem;
        text-align: center;
        pointer-events: none;
        position: relative;
        user-select: none;

        &::after {
          display: block;
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          width: 100%;
          border-bottom: 1px solid colors.$mmc-accent-gray;
        }

        & > span {
          background: colors.$mmc-soft-gray;
          border-radius: 0.25rem;
          color: colors.$mmc-soft-slate;
          display: inline-block;
          padding: 0.125rem 1.5rem;
          position: relative;
          z-index: 2;
        }

        &.designMode {
          &::after {
            border-bottom: 1px solid colors.$mmc-gray;
          }
        }
      }

      .empty {
        background: none !important;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
