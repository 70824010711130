@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .unknown {
        color: colors.$mmc-disabled-gray;
      }
    }
  }
}
