@use "styles/layout";
@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }

      .left {
        width: 70%;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .right {
        padding-top: 1.85rem;
        width: 30%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .modeBtn {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        user-select: none;
        white-space: nowrap;
        &[data-disabled] {
          color: colors.$mmc-soft-slate;
        }
      }

      .coordinatesFields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: layout.$spacer-m;
      }

      .address {
        margin-top: -1.5rem;
      }

      .hidden {
        :global(.ant-form-item-control-input),
        :global(.ant-form-item-label) {
          display: none;
        }
      }

      .popconfirm {
        :global(.ant-btn) {
          height: 2rem;
        }
      }

      .coordinates {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        width: 25rem;

        :global(.ant-form-item) {
          margin-bottom: 0;
        }
      }
    }
  }
}
