@use "styles/colors";
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        max-width: 40vw;
        background: colors.$mmc-soft-gray;
        color: colors.$mmc-soft-slate;
        display: flex;
        flex-direction: column;
        font-size: 0.875rem;
        gap: 0.25rem;
        margin: 0 -1.25rem;
        padding: 0.75rem 1.25rem;
        min-height: 4.5rem;
      }

      .selector {
        white-space: nowrap;
        user-select: none;
      }

      .addressLabel {
        font-size: 0.75rem;
      }

      .entity {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        &.compact {
          flex-direction: row;
          gap: 1.25rem;
        }
      }

      .entityInfo {
        color: colors.$mmc-soft-slate;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .entityName {
          color: colors.$mmc-primary-100;
          font-size: 0.875rem;
          font-weight: 500;
        }

        .entityAddress {
          font-size: 0.75rem;
        }
      }

      .btn {
        background: none;
        border: none;
        font-weight: normal;
        height: auto;
        line-height: normal;
        margin: 0;
        padding: 0 0.5rem;

        & > span + :global(.fa-angle-down) {
          margin-left: 0.25rem;
          max-width: 0.6rem;
          vertical-align: middle;
        }

        &:global(.ant-dropdown-open) {
          color: colors.$mmc-primary-075;
        }

        &:last-of-type {
          padding-right: 0;
        }
      }

      .menu {
        border-radius: 0.375rem;
        margin: 0;
        padding: 0.25rem;

        :global(.ant-dropdown-menu-item) {
          border-radius: 0.25rem;
          user-select: none;

          &:global(.ant-dropdown-menu-item-selected) {
            background-color: colors.$mmc-primary-010;
            color: inherit;
            font-weight: 500;
          }
        }
      }

      .changeBtn {
        font-size: 0.875rem;
      }

      .helpLink {
        font-size: 0.75rem;
      }

      .current {
        color: colors.$mmc-slate;
        font-weight: 500;
      }

      .hasFeedback {
        & :global(.ant-select-auto-complete) {
          & :global(.ant-select-selection-search-input) {
            border-color: colors.$mmc-red-100;
            box-shadow: 0 0 0.25rem #{color.change(colors.$mmc-red-100, $alpha: 0.5%)};
          }
        }

        .feedback {
          color: colors.$mmc-red-100;
          margin-top: 0.25rem;
          font-size: 0.75rem;
        }
      }
    }
  }
}
