@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .disabledIcon {
        background-color: colors.$mmc-soft-gray !important;
        color: colors.$mmc-disabled-gray !important;
        border-color: colors.$mmc-disabled-gray !important;
      }

      .textMargin {
        margin-left: 0.25rem;
      }

      .disabledOption {
        margin-left: 0.25rem;
      }
    }
  }
}
