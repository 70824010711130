@use "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .description {
        font-size: 0.75rem;
        color: colors.$mmc-disabled-gray;
        margin-left: 0.25rem;
        cursor: default;

        &.canHover:hover {
          color: colors.$mmc-slate;
        }
      }

      .attendee {
        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
      }

      .tooltipOverlay {
        max-width: initial;
      }
    }
  }
}
