@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .content {
        min-width: 20rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: colors.$mmc-slate;
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .small {
        height: 1.75rem;
        width: 1.75rem;

        & > * {
          font-size: 0.75rem;
        }
      }
    }
  }
}
