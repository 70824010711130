:global {
  body[class*="theme-"].theme {
    :local {
      .modal {
        padding: 0;

        & :global(.ant-modal-content) {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          height: 60vh;
          overflow: hidden;

          & :global(.ant-modal-header) {
            padding-bottom: 0;
          }

          & :global(.ant-modal-body) {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
          }

          & :global(.ant-modal-footer) {
            flex-shrink: 0;
            padding: 1rem 1.5rem;
          }
        }
      }
    }
  }
}
