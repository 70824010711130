@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .alert {
        margin-bottom: layout.$spacer-m;
      }
    }
  }
}
