:global {
  body[class*="theme-"].theme {
    :local {
      .title {
        font-size: 1rem;
        margin-bottom: 0.75rem;
      }
      .description {
        font-size: 0.875rem;
      }
    }
  }
}
