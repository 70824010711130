@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .buttonIcon {
        margin-right: layout.$spacer-s;
      }

      .modal :global(.ant-modal-body) {
        display: flex;
        flex-direction: column;
        gap: layout.$spacer-l;
      }
    }
  }
}
