@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.ant-picker) {
          background: transparent;
          border: none;
          box-shadow: none;
          color: colors.$mmc-soft-slate;
          cursor: pointer;
          outline: none;
          padding: 0;
          width: 0;
          overflow: hidden;
        }
      }

      .tooltip {
        :global(.ant-tooltip-inner) {
          color: colors.$mmc-white;
          background-color: colors.$mmc-slate;
        }
        :global(.ant-tooltip-arrow-content) {
          background-color: colors.$mmc-slate;
        }
      }

      .btn {
        background: none;
        border: none;
        box-shadow: none;
        color: colors.$mmc-soft-slate;
        font-size: 1rem;
        font-weight: 500;
        height: auto;
        line-height: normal;
        outline: none;
        padding: 0.25rem 0.5rem;

        &:active,
        &:hover,
        &:focus {
          background: transparent;
        }

        &[ant-click-animating-without-extra-node]:after {
          display: none;
        }

        &.empty {
          color: colors.$mmc-disabled-gray;
        }

        &.active {
          color: colors.$mmc-primary;
        }

        &:not(.active) {
          &:hover {
            color: colors.$mmc-slate;
          }
        }
      }

      .group {
        align-items: center;
        border-radius: 0.25rem;
        color: colors.$mmc-slate;
        display: flex;
        flex-direction: row;

        span.separator {
          font-weight: bold;
        }

        &:hover {
          background-color: colors.$mmc-white;
          box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
        }

        &.active,
        &:active {
          background-color: colors.$mmc-white;
          box-shadow: 0 0 0.25rem #1890ff !important;
        }
      }

      .panel {
        color: colors.$mmc-accent-gray;

        :global(.ant-picker-panel-container) {
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        }

        :global(.ant-picker-time-panel-column),
        :global(.ant-picker-panel),
        :global(.ant-picker-panel-focused) {
          border: none;
        }

        :global(.ant-picker-time-panel-cell) {
          :global(.ant-picker-time-panel-cell-inner) {
            color: colors.$mmc-soft-slate;
          }

          &:global(.ant-picker-time-panel-cell-selected) {
            :global(.ant-picker-time-panel-cell-inner) {
              background: colors.$mmc-soft-gray;
            }
          }
        }

        :global(.ant-picker-footer) {
          border: none;
          color: colors.$mmc-soft-slate;
          display: flex;
          flex-direction: column-reverse;
          gap: 0.75rem;
          padding: 0.75rem 0.625rem 0.75rem 1rem;

          :global(.ant-picker-footer-extra) {
            border: none;
            font-size: 0.75rem;
            line-height: normal;
            padding: 0;
          }
        }

        :global(.ant-picker-ranges) {
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-shrink: 0;
          justify-content: space-between;
          line-height: 1.5rem;
          margin: 0;
          padding: 0;

          :global(.ant-picker-ok) {
            float: none;
            margin: 0;

            :global(.ant-btn) {
              background: colors.$mmc-soft-gray;
              border: 1px solid colors.$mmc-accent-gray;
              box-shadow: none;
              color: colors.$mmc-soft-slate;
              outline: none;

              &[ant-click-animating-without-extra-node]:after {
                display: none;
              }

              &:active,
              &:hover {
                border-color: colors.$mmc-soft-slate;
              }
            }
          }
        }

        &.panelEnd {
          :global(.ant-picker-footer) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }
          .clearButton {
            font-size: 0.875rem;
            color: colors.$mmc-soft-slate;
          }
        }
      }

      .checkbox {
        color: colors.$mmc-soft-slate;
      }

      .action {
        text-align: center;
      }

      .clear {
        color: colors.$mmc-soft-slate;
        text-decoration: underline;

        &:hover {
          color: colors.$mmc-slate;
        }
      }

      .overlay {
        background: colors.$mmc-white;
        box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
      }

      .menu {
        box-shadow: none;
        color: colors.$mmc-soft-slate;

        :global(.ant-dropdown-menu-item) {
          color: inherit;
        }
      }
    }
  }
}
