@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .alert {
        margin-top: 1.5rem;
        :global(.ant-modal-content) {
          width: 32.5rem;
        }
        :global(.ant-modal-body) {
          padding: 2rem 2rem 1.5rem 2rem;
        }
        :global(.anticon) {
          color: colors.$mmc-primary-100;
        }
        :global(.ant-modal-confirm-content) {
          margin-top: 1rem;
        }
        :global(.ant-modal-confirm-title) {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }

      .alertWrapper {
        //To make this alert visible in front of activity annotation modal
        z-index: 1031;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
      }

      .date {
        background-color: colors.$mmc-soft-gray;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        font-weight: 700;
      }
    }
  }
}
