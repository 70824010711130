:global {
  body[class*="theme-"].theme {
    :local {
      .full {
        width: 100%;
      }

      .funnelStage {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 100%;

        & > div {
          flex-basis: 50%;
          flex-grow: 0;
          overflow: hidden;
        }
      }

      .defaultValueWarning {
        margin-top: 1rem;
      }
    }
  }
}
