@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
      }

      .group {
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        &.hover {
          background: colors.$mmc-white;
        }
      }

      .tooltip {
        :global(.ant-tooltip-inner) {
          color: colors.$mmc-white;
          background-color: colors.$mmc-slate;
        }
        :global(.ant-tooltip-arrow-content) {
          background-color: colors.$mmc-slate;
        }
      }

      .btn {
        align-items: center;
        background: none;
        border: none;
        box-shadow: none;
        color: colors.$mmc-soft-slate;
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        font-weight: 500;
        gap: 1rem;
        height: auto;
        line-height: normal;
        outline: none;
        padding: 0.25rem 0.5rem;

        &:focus {
          color: inherit;
        }

        &[ant-click-animating-without-extra-node]:after {
          display: none;
        }

        .label {
          min-width: 6.5rem;
          text-align: left;
        }

        :global(.ant-switch) {
          pointer-events: none;
        }

        &:hover {
          background-color: colors.$mmc-white;
          box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
          color: colors.$mmc-slate;
        }

        &:active,
        &.active {
          background-color: colors.$mmc-white;
          box-shadow: 0 0 0.25rem #1890ff !important;
          color: colors.$mmc-primary;
        }
      }

      .popover {
        :global(.ant-popover-arrow) {
          display: none;
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 1rem;

        .title {
          font-size: 1rem;
          font-weight: 500;
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .settings {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 0.25rem;

          .label {
            padding-right: 1rem;
            white-space: nowrap;
          }

          .number {
            width: 4.5rem;
          }

          .unit {
            width: 7rem;
          }
        }
      }

      .icon {
        color: colors.$mmc-disabled-gray;
        width: 1.5rem;

        .active {
          color: colors.$mmc-primary;
        }
      }
    }
  }
}
