:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
      }

      .select {
        width: 100%;

        & > :global(.ant-select-selector) {
          & > :global(.ant-select-selection-overflow) {
            row-gap: 0.125rem;
          }
        }
      }

      .sectionTitle {
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        line-height: 1.125;
      }

      .buttonIcon {
        margin-right: 0.5rem;
      }

      .addBtn {
        font-weight: 500;
      }
    }
  }
}
