:global {
  body[class*="theme-"].theme {
    :local {
      .container,
      .email {
        display: inline;
      }
    }
  }
}
