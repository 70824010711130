@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .inputGroup {
        border: 1px solid colors.$mmc-accent-gray;
        border-radius: 0.25rem;

        &:hover {
          border-color: colors.$mmc-primary-050;
        }
      }

      .selectField {
        width: 100% !important; // otherwise overridden when used in forms because ant-form-item adds more specificity and it would be hard to beat it otherwise
        & > :global(.ant-select-selector) {
          border: none !important;
          box-shadow: none !important;
        }
      }

      .note {
        margin-top: 0.25rem;
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
      }

      .space {
        padding: 0.125rem;
      }
    }
  }
}
