@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 2.25rem;
      }

      .alert {
        border: none;
        padding: 1.25rem 1.5rem;
      }

      .requestTitle {
        color: colors.$mmc-soft-slate;
        font-size: 1rem;
      }

      .description {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        margin-bottom: 1rem;
        line-height: 1.375rem;
      }

      .dataContainer {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }

      .requestData {
        color: colors.$mmc-slate;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
      }

      .username {
        color: colors.$mmc-slate;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.75rem;
      }

      .buttonContainer {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
      }

      .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 0.75rem;
      }

      .button {
        width: 50%;
      }
    }
  }
}
