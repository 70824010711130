@use "styles/colors";
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .topArrow {
        top: 0.5rem;
      }

      .bottomArrow {
        bottom: 0.5rem;
      }

      .leftArrow {
        left: -0.5rem;
        border-right: 0.625rem solid transparent;
      }

      .rightArrow {
        right: -0.5rem;
        border-left: 0.625rem solid transparent;
      }

      .leftBorder {
        left: 0;
        border-left: 0.25rem solid transparent;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }

      .rightBorder {
        right: 0;
        border-right: 0.25rem solid transparent;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }
  }
}
