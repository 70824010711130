@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .modalTitle {
        font-size: 1.2rem;
      }

      .modalSubtitle {
        font-size: 0.875rem;
        font-weight: normal;
      }

      .subtitleLimitExplanation {
        display: inline-block;
      }

      .displayNone {
        opacity: 0;
      }

      .lossReasonField {
        color: colors.$mmc-red-100;
      }

      .rottingDaysOut {
        color: colors.$mmc-yellow-100;
      }
    }
  }
}
