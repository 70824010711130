:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        &:focus {
          outline: none;
        }
      }
    }
  }
}
