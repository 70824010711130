@use "../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
      }
      .line {
        padding: 0.5rem 0;
      }
      .shadowed {
        box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.06);
      }
      .bottom {
        display: flex;
        gap: 0.5rem;
        justify-content: space-between;
      }
      .activityTypes {
        width: 100%;
      }
      .staticValue {
        font-weight: 500;
        background-color: colors.$mmc-white;
      }
      .removeButtonWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .changesWrapper {
        width: 100%;
      }
      .readonlyActivityTypes {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
      .readonlyActivityTypeIcon {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
