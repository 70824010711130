@use "styles/colors";
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        min-width: 22.5rem;
        border-radius: 0.25rem;
        overflow: hidden;
      }

      .gradientContainer {
        height: 2.5rem;
        background: colors.$mmc-white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 2rem;
      }

      .overlay {
        border-radius: 0.25rem;
        z-index: 1050; // Higher than other popovers

        :global(.ant-popover-inner) {
          border-top: none;
        }

        :global(.ant-popover-inner-content) {
          padding: 0;
        }
      }

      .metrics {
        display: flex;
        gap: 0.75rem;
        min-height: 9.5rem;
        min-width: 32.75rem;
        padding: 0.5rem 1.5rem 1rem 1.5rem;
      }

      .addMetric {
        height: 8rem;
        width: 14.5rem;
        border-style: dashed;
        border-color: colors.$mmc-accent-gray;

        &:hover {
          border-color: colors.$mmc-primary-025;
          color: colors.$mmc-primary-100;
          background-color: colors.$mmc-primary-005;
        }

        &:focus {
          box-shadow: 0px 0px 4px 0px rgba(24, 144, 255, 0.5);
          border-color: colors.$mmc-primary-025;
          color: colors.$mmc-primary-100;
          background-color: colors.$mmc-primary-005;
        }
      }

      .teamSelect {
        width: 100%;
        display: flex;
        gap: 0.25rem;
        padding: 0 1.5rem;
      }

      .dropdown {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-weight: 500;
      }

      .menu {
        max-height: 8.75rem;
        overflow: auto;
      }

      .footer {
        height: 2rem;
        background-color: colors.$mmc-soft-gray;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .open {
        font-weight: 500;
      }
    }
  }
}
