@import "../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        color: $mmc-red-100;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        align-items: center;
      }

      .valid {
        color: $mmc-green-100;
      }
    }
  }
}
