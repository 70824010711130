:global {
  body[class*="theme-"].theme {
    :local {
      .btn {
        border: none;
        height: auto;
        line-height: normal;
        padding: 0;
        margin: 0;
      }
    }
  }
}
