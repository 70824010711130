@use "styles/colors" as *;
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.25rem;
        background-color: $mmc-white;

        &:global(.ant-btn) {
          // !important here is used to override another !important made by ant-d devs
          // as a work around for codesandbox chrome demo issues
          // check node_modules/antd/lib/button/style/index.less line 238 for details
          padding: 0.25rem 0.75rem !important;
        }

        &.primary:not(:disabled) {
          color: $mmc-primary;

          &.bordered {
            border-color: $mmc-primary-050;
          }

          &:not(.bordered) {
            background-color: $mmc-primary-005;
            border: 0;
          }

          &:hover {
            background-color: $mmc-primary-010;
          }

          &:focus {
            background-color: $mmc-primary-010;
            box-shadow: 0 0 4px rgba(24, 144, 255, 0.5);
          }

          &.active,
          &:active {
            background-color: $mmc-primary;
            color: $mmc-white;
          }
        }

        &.secondary:not(:disabled) {
          color: $mmc-soft-slate;

          &.bordered {
            border-color: $mmc-accent-gray;
          }

          &:hover {
            background-color: $mmc-soft-gray;
          }

          &:not(.bordered) {
            background-color: $mmc-soft-gray;
            border: 0;
            &:hover {
              background-color: color.adjust($mmc-slate, $alpha: -0.9);
            }
          }

          &:focus {
            background-color: $mmc-soft-gray;
            box-shadow: 0 0 4px rgba(24, 144, 255, 0.5);
          }

          &.active,
          &:active {
            background-color: $mmc-soft-gray;
            border-color: $mmc-soft-slate;
          }
        }

        &.danger:not(:disabled) {
          border-color: $mmc-red-050;
          color: $mmc-red-100;

          &:hover {
            background-color: $mmc-red-010;
          }

          &:focus {
            background-color: $mmc-red-010;
            box-shadow: 0 0 4px rgba(245, 34, 45, 0.5);
          }

          &.active,
          &:active {
            background-color: $mmc-red-100;
            color: $mmc-white;
          }
        }

        &:disabled {
          background-color: $mmc-soft-gray;
          border-color: $mmc-accent-gray;
          color: $mmc-disabled-gray !important;
        }
      }
    }
  }
}
