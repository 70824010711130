@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
      }
      .queueCount {
        font-size: 1.25rem;
        font-weight: 600;
        color: colors.$mmc-slate;
      }
    }
  }
}
