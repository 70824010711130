@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        flex: 1;
        padding: 0 20%;
      }
      .title {
        font-size: 1.5rem;
        font-weight: 600;
        color: colors.$mmc-slate;
      }
      .subTitle {
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
