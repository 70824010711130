@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .conjunction {
        font-weight: 500;
        text-transform: uppercase;
        color: colors.$mmc-accent-gray;
        margin: 0 0.25rem;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
