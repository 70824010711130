@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        height: 100%;
        background-color: colors.$mmc-white;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 0.375rem;
        padding: 0 layout.$spacer-l layout.$spacer-l layout.$spacer-l;
      }
      .groupHeader {
        height: 2rem;
        padding: layout.$spacer-s layout.$spacer-xs;
      }
      .groupHeaderTitle {
        text-transform: uppercase;
        font-size: 0.75rem;
        color: colors.$mmc-disabled-gray;
        font-weight: 500;
      }
      .upToDate {
        color: colors.$mmc-gray;
        font-weight: 500;
      }
    }
  }
}
