@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .textFieldIcon {
        display: inline-flex;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        background-color: colors.$mmc-primary-010;
      }

      .iconColor {
        color: colors.$mmc-primary-100;
      }
    }
  }
}
