:global {
  body[class*="theme-"].theme {
    :local {
      .mapContainer {
        height: 100%;
      }
      .map {
        width: 100%;
        height: 100%;
      }
    }
  }
}
