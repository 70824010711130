@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .title {
        display: flex;
        justify-content: space-between;
        gap: 0.25rem;
      }

      .icon {
        color: colors.$mmc-accent-gray;
      }

      .files {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: layout.$spacer-xs;
      }

      .noFiles {
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
