@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
      }

      .group {
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        &.hover {
          background: colors.$mmc-white;
        }
      }

      .tooltip {
        :global(.ant-tooltip-inner) {
          color: colors.$mmc-white;
          background-color: colors.$mmc-slate;
        }
        :global(.ant-tooltip-arrow-content) {
          background-color: colors.$mmc-slate;
        }
      }

      .switch {
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        font-weight: 500;
        gap: 1rem;
        height: auto;
        line-height: normal;
        outline: none;
        padding: 0.25rem 0.5rem;

        .label {
          min-width: 6.5rem;
          text-align: left;
        }

        &:hover {
          background-color: colors.$mmc-white;
          box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
          color: colors.$mmc-slate;
        }

        &:active {
          background-color: colors.$mmc-white;
          box-shadow: 0 0 0.25rem #1890ff !important;
          color: colors.$mmc-primary;
        }
      }

      .icon {
        color: colors.$mmc-disabled-gray;
        width: 1.5rem;

        .active {
          color: colors.$mmc-green-100;
        }
      }
    }
  }
}
