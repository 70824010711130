@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      div.container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.75rem;
        span {
          white-space: nowrap;
        }
      }
      .counter {
        font-size: 0.875rem;
        font-weight: 700;
      }
      .frequencyInterval {
        margin: 0.5rem 0;
      }
    }
  }
}
