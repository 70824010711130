@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding-top: 2rem;
      }
      .image {
        width: 9.25rem;
        height: 9.25rem;
      }
      .title {
        font-size: 1.5rem;
        color: colors.$mmc-slate;
        font-weight: 500;
      }
      .subTitle {
        font-size: 0.875rem;
        font-weight: 400;
        color: colors.$mmc-slate;
        text-align: center;
      }
      .bottom {
        display: flex;
        justify-content: center;
      }
    }
  }
}
