@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        font-size: 1.125rem;
        color: colors.$mmc-slate;
        margin: 0;
        cursor: pointer;
      }
    }
  }
}
