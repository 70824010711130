@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        position: relative;
        display: flex;
        width: 14.5rem;
        height: 8rem;
        padding: 0.75rem;
        flex-direction: column;
        justify-content: center;
        border-radius: 0.5rem;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
        gap: 0.25rem;
        background-color: colors.$mmc-white;
      }

      .content {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
      }

      .count {
        font-size: 3rem;
        font-weight: 500;
        line-height: 4rem;
        color: colors.$mmc-slate;
      }

      .titleContainer {
        display: flex;
        flex-direction: column;
      }

      .title {
        font-size: 0.875rem;
        line-height: 1.375rem;
        font-weight: 500;
        color: colors.$mmc-slate;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-transform: uppercase;
      }

      .subtitle {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.25rem;
        color: colors.$mmc-soft-slate;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .removeIcon {
        color: colors.$mmc-red-075;
        position: absolute;
        right: -0.25rem;
        top: -0.25rem;
        display: none;
      }

      .container:hover > .removeIcon {
        display: block;
      }

      .popconfirm {
        max-width: 19.5rem;

        :global(.ant-popover-inner) {
          padding: 0.75rem 1rem;
        }
      }
    }
  }
}
