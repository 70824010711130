@use "../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding: 1.5rem 1rem;
        background-color: colors.$mmc-white;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
      }
      .title {
        color: colors.$mmc-slate;
        font-weight: 500;
        text-transform: uppercase;
      }
      .groupHeader {
        //margin: 0.5rem;
        padding: 0.25rem;
        border-radius: 0.25rem;
        background-color: colors.$mmc-soft-gray;
      }
      .groupName {
        background-color: colors.$mmc-white;
        cursor: pointer;
        padding: 0 0.25rem;
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .noFrequencyText {
        font-size: 0.875rem;
        color: colors.$mmc-slate;
        width: 12rem;
        text-align: center;
      }
      .noFrequencyIcon {
        width: 7rem;
        height: 7rem;
      }
      .noFrequency {
        height: 16rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        color: colors.$mmc-accent-gray;
      }
      .addFrequency {
        width: fit-content;
      }
    }
  }
}
