@use "../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        min-width: 20rem;
        max-width: 75vw;

        display: flex;
        flex-direction: column;
        padding: 0.5rem;

        @media screen and (min-width: layout.$breakpoint-desktop) {
          min-width: 30rem;
        }
        @media screen and (min-width: layout.$breakpoint-wide-desktop) {
          min-width: 40rem;
        }
      }

      .map {
        min-height: 200px;
        height: 25vh;
        width: 100%;
        margin-bottom: 1rem;
        @media screen and (min-height: layout.$breakpoint-tablet-portrait) {
          height: 35vh;
        }
      }

      .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
      }
    }
  }
}
