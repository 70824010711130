@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .overlay {
        z-index: 1060; // greater than 1050 which is used for selectbox dropdown
      }

      .deleteIcon {
        font-size: 1rem;
        color: colors.$mmc-red-050;
        display: block;
        &:focus-within,
        &:hover {
          color: colors.$mmc-red-075;
        }
      }
    }
  }
}
