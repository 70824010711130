@use "styles/colors";
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        background: colors.$mmc-slate;
        border: none;
        border-radius: 0.25rem;
        box-shadow: 0 0.125rem 0.5rem #{color.change(colors.$mmc-black, $alpha: 0.15)};
        color: colors.$mmc-white;
        cursor: default;
        font-size: 0.875rem;
        outline: none;
        padding: 0.25rem 0.5rem;
        pointer-events: none;
        position: relative;
        transform: translate(-50%, -3rem);
        user-select: none;
      }

      .arrow {
        position: absolute;
        left: 50%;
        bottom: -0.3rem;
        margin-left: -0.375rem;
        width: 0;
        height: 0;
        border-left: 0.375rem solid transparent;
        border-right: 0.375rem solid transparent;
        border-top: 0.375rem solid colors.$mmc-slate;
      }

      .text {
        max-width: 25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
