:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 1rem;
      }

      .left {
        flex-basis: 2rem;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        padding-top: 2px; // to better adjust left icon's top and right content's top
      }

      .right {
        justify-self: flex-start;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex-basis: calc(100% - 3rem);
        width: calc(100% - 3rem);
      }
    }
  }
}
