//Global animation to be used anywhere in web
.animateDoubleBounce {
  animation-duration: 1s;
  animation-iteration-count: 1;
  transform-origin: top;
  animation-name: doubleBounceKeyFrames;
  animation-timing-function: linear;
}

@keyframes doubleBounceKeyFrames {
  0% {
    transform: scale(1, 1) translateY(-10px);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(0);
  }
  30% {
    transform: scale(1.1, 0.9) translateY(10px);
  }
  64% {
    transform: scale(1, 1) translateY(-7px);
  }
  100% {
    transform: scale(1, 1) translateY(0px);
  }
}
