@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .modalContainer {
        min-width: 55rem;
        min-height: 38rem;
        max-height: max(38rem, 65vh);

        :global(.ant-modal-body) {
          height: clamp(500px, 50vh, 600px);
        }

        :global(.ant-modal-footer) {
          padding: 1rem;
        }
      }

      .container {
        display: flex;
        padding: 1rem 0;
        height: 100%;
        gap: 1rem;
        background-color: colors.$mmc-white;
      }

      .title {
        display: flex;
        gap: 0.75rem;
        font-size: 1.25rem;
        font-weight: 500;
        color: colors.$mmc-slate;
      }

      .preview {
        display: flex;
        flex-direction: column;
        padding: 0.75rem;
        gap: 0.75rem;
        min-width: 16rem;
        border-radius: 0.25rem;
        background-color: colors.$mmc-soft-gray;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
        color: colors.$mmc-slate;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.75rem;
        height: 12.5rem;
        justify-content: center;
      }

      .previewDescription {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem;
        height: 8rem;
        background: colors.$mmc-white;
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.375rem;
        text-transform: uppercase;
        padding: 3rem 1.5rem;
        text-align: center;
      }

      .charts {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-left: 1.5rem;
        align-items: flex-start;
      }

      .alert {
        width: 100%;
      }

      .chartsList {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.75rem;
        overflow: auto;
        padding: 0.5rem;
      }

      .chart {
        height: 4.5rem;
        border-radius: 0.25rem;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.125rem;

        &:hover {
          background-color: colors.$mmc-soft-gray;
        }
      }

      .chartTitle {
        color: colors.$mmc-slate;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
      }

      .subtext {
        color: colors.$mmc-soft-slate;
      }

      .noCharts {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .noChartTitle {
        color: colors.$mmc-slate;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.5rem;
      }

      .search {
        width: 100%;
        min-height: auto;
      }

      .selectedCard {
        background: colors.$mmc-primary-005;
        border: 1px solid colors.$mmc-primary-050;

        &:hover {
          background-color: colors.$mmc-primary-005;
        }
      }
    }
  }
}
