@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .map {
        width: 600px;
        height: 500px;
      }

      .address {
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
