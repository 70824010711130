@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.ant-modal-title) {
          padding: 1rem 0.5rem 0;
        }
      }

      .icon {
        color: colors.$mmc-primary;
        margin-right: 0.25rem;
      }

      .radioGroup {
        padding-left: 1.5rem;
      }
    }
  }
}
