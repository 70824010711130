@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        cursor: pointer;
        width: fit-content;
      }
      .text {
        color: colors.$mmc-primary-100;
        text-decoration: underline;
      }
      .icon {
        color: colors.$mmc-primary-100;
      }
    }
  }
}
