@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        margin-left: 0.5rem;
        display: inline-flex;
        align-items: center;
        gap: 0.25rem;
        font-size: 0.875rem;
        font-weight: 500;
        --fa-secondary-opacity: 1;
      }

      .positive {
        color: colors.$mmc-green-100;
        --fa-primary-color: #{colors.$mmc-green-100};
        --fa-secondary-color: #{colors.$mmc-green-010};
      }

      .negative {
        color: colors.$mmc-red-100;
        --fa-primary-color: #{colors.$mmc-red-100};
        --fa-secondary-color: #{colors.$mmc-red-010};
      }

      .neutral {
        color: colors.$mmc-slate;
        --fa-primary-color: #{colors.$mmc-slate};
        --fa-secondary-color: #{colors.$mmc-soft-gray};
      }
    }
  }
}
