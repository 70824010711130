@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .groupBadgeContainer {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: colors.$mmc-white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .groupName {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
}
