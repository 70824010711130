@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        font-size: 0.75rem;
        font-weight: 400;
        font-style: italic;
        line-height: 1rem;
        color: colors.$mmc-soft-slate;
      }
      .icon {
        color: colors.$mmc-yellow-100;
      }
    }
  }
}
