@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .collapse {
        background-color: colors.$mmc-white;
      }
    }
  }
}
