@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        border-radius: 0.25rem;
        width: 100%;
        margin: 0 2rem;
        background-color: colors.$mmc-soft-gray;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .infoText {
        color: colors.$mmc-soft-slate;
        font-weight: 400;
        font-size: 0.875rem;
      }
      .infoValue {
        color: colors.$mmc-slate;
        font-weight: 500;
        font-size: 0.875rem;
      }
    }
  }
}
