@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .title {
        font-size: 1.25rem;
        font-weight: 500;
        color: colors.$mmc-slate;
      }

      .subTitle {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
      }
    }
  }
}
