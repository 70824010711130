@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .file {
        width: 100%;
        border-radius: 0.5rem;
        display: flex;
        gap: layout.$spacer-s;
        align-items: center;
        justify-content: space-between;

        cursor: pointer;
        background-color: colors.$mmc-soft-gray;

        &:hover {
          background-color: colors.$mmc-primary-005;
        }
      }

      .title {
        min-width: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.75rem;
      }

      .fileIcon {
        flex-shrink: 0;
      }

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .actions {
        flex-shrink: 0;
        justify-self: flex-end;

        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
      }

      .large {
        padding: 0.5rem 0.25rem 0.5rem 1rem;
      }

      .small {
        padding: 0.25rem 0.25rem 0.25rem 0.5rem;
      }
    }
  }
}
