@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        width: 26rem;
        height: 75vh;
        min-height: 34rem;
        background-color: colors.$mmc-soft-gray;
        display: flex;
        flex-direction: column;
      }
      .top {
        padding: layout.$spacer-m layout.$spacer-l;
      }
      .title {
        font-size: 1.25rem;
        font-weight: 500;
      }
      .icon {
        color: colors.$mmc-disabled-gray;
        cursor: pointer;
      }
      .tabs {
        & > :global(.ant-tabs-nav) {
          padding: 0 layout.$spacer-l;
          margin: 0;
          & > :global(.ant-tabs-nav-wrap) > :global(.ant-tabs-nav-list) {
            width: 100%;
            & > :global(.ant-tabs-tab) {
              flex: 1;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
