@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .modal {
        width: 48rem;
        border: none;
        :global(.ant-divider) {
          border-color: colors.$mmc-accent-gray;
          margin-top: 0;
        }
      }

      .recordName {
        padding: 0;
      }

      .image {
        margin-right: 1.5rem;
      }

      .title {
        display: flex;
      }

      .okButton {
        width: 10rem;
        justify-content: center;
        border-color: none;
        & > span {
          color: colors.$mmc-white;
        }
      }

      .okButton:global(.ant-btn-round) {
        &:not([disabled]),
        &:not(:disabled) {
          border-color: colors.$mmc-green-075;
          background-color: colors.$mmc-green-100;

          &:hover {
            background-color: colors.$mmc-green-075;
          }
        }
      }

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 3rem;
        padding-bottom: 0;
      }

      .footer {
        display: flex;
        justify-content: center;
        padding: 1.5rem;
      }

      .backgroundImage {
        width: clamp(750px, 50vw, 1024px);
        position: absolute;
        top: 0;
        left: 0;
      }

      .card {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          background-color: colors.$mmc-soft-gray;
          cursor: pointer;
        }
      }

      .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem 1rem;
        padding: 1rem 0;
      }
    }
  }
}
