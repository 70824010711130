@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.ant-dropdown-trigger) {
          width: 1.25rem;
        }
      }
      .icon {
        width: 0.6rem;
      }
    }
  }
}
