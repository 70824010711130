@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .btn {
        display: block;
        width: 100%;

        & :global(.ant-btn) {
          background: colors.$mmc-white;
          border: none;
          border-radius: 50%;
          box-shadow: none;
          color: colors.$mmc-soft-slate;
          display: block;
          font-size: 1.125rem;
          font-weight: normal;
          height: 2.5rem;
          line-height: 2.5rem;
          margin: 0;
          outline: none !important;
          padding: 0;
          width: 100%;

          &[disabled] {
            color: colors.$mmc-disabled-gray;
          }

          &:hover:not([disabled]):not(:active) {
            color: colors.$mmc-primary-050;
          }

          &[ant-click-animating-without-extra-node]:after {
            border: 0 none;
            opacity: 0;
            animation: none;
          }

          &.active,
          &:active {
            color: colors.$mmc-primary-100;
          }
        }
      }
    }
  }
}
