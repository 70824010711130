@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .containerForSolidText {
        margin-top: layout.$spacer-m;
      }

      .container {
        display: flex;
        align-items: center;
        gap: layout.$spacer-xs;
        &.inline {
          display: inline-flex;
        }
      }

      .text {
        margin: 0;
      }

      .icon {
        color: colors.$mmc-accent-gray;
        &.sized {
          width: 0.875rem;
          height: 0.875rem;
        }
      }
    }
  }
}
