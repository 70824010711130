@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .bar {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        width: 100%;
      }

      .control {
        width: 70%;
        max-width: 70%;
      }

      .select {
        width: 100%;
      }

      .cancel {
        align-self: flex-end;
        background: none;
        border: none;
        color: colors.$mmc-disabled-gray;
        margin-left: auto;

        &:hover {
          background: none;
          color: colors.$mmc-slate;
        }
      }
    }
  }
}
