:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding-top: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
      }
    }
  }
}
