:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
      }

      .fullWidth {
        flex: 1;
      }
    }
  }
}
