@use "../../../styles/colors";
@use "../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .modalWrapper {
        z-index: 1037; // we need to cover at lease activity annotation modal and main email modal and confirmation modal
      }
      .container {
        :global(.ant-modal-body) {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          max-height: 500px;
          overflow: auto;
        }
      }
      .point {
        font-weight: 500;
        color: colors.$mmc-slate;
      }
      .description {
        font-weight: 400;
        font-size: 0.875rem;
        color: colors.$mmc-soft-slate;
      }
      .link {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 500;
        margin-top: 0.5rem;
      }
    }
  }
}
