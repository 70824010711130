@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .menu {
        border: none;
        box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.15);
        color: colors.$mmc-soft-slate;

        :global(.ant-menu-item) {
          margin: 0;
          padding: 0 0.75rem;
          height: 2rem;
          line-height: 2rem;
          user-select: none;
        }
      }
    }
  }
}
