@use "../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .collapse {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.06);
      }

      .collapsePanel {
        :global(.ant-collapse-content) {
          :global(.ant-collapse-content-box) {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
        }
        margin-bottom: 1rem;
      }
      .collapseHeader {
        margin-right: 2rem;
      }
      .title {
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
