@import "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}
