@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
      }

      .title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      .description {
        text-align: center;
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
    }
  }
}
