@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.ant-picker) {
          background: transparent;
          border: none;
          box-shadow: none;
          cursor: pointer;
          color: colors.$mmc-slate;
          outline: none;
          padding: 0;
          width: 0;
          overflow: hidden;
        }
      }

      .tooltip {
        :global(.ant-tooltip-inner) {
          color: colors.$mmc-white;
          background-color: colors.$mmc-slate;
        }
        :global(.ant-tooltip-arrow-content) {
          background-color: colors.$mmc-slate;
        }
      }

      .btn {
        background: none;
        border: none;
        box-shadow: none;
        color: colors.$mmc-slate;
        font-size: 1rem;
        font-weight: 500;
        height: auto;
        line-height: normal;
        outline: none;
        padding: 0.25rem 0.5rem;

        &:focus {
          color: colors.$mmc-slate;
        }

        &[ant-click-animating-without-extra-node]:after {
          display: none;
        }

        &:hover {
          background-color: colors.$mmc-white;
          box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
          color: colors.$mmc-slate;
        }

        &.active,
        &:active {
          background-color: colors.$mmc-white;
          box-shadow: 0 0 0.25rem #1890ff !important;
          color: colors.$mmc-primary;
        }
      }

      .panel {
        :global(.ant-picker-panel-container) {
          box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.15);
        }

        :global(.ant-picker-footer) {
          border-top: 1px solid colors.$mmc-accent-gray;
          color: colors.$mmc-soft-slate;
          display: flex;
          flex-direction: column-reverse;
          gap: 0.75rem;
          padding: 0.75rem 0;

          :global(.ant-picker-today-btn) {
            line-height: normal;
            padding: 0;
          }

          :global(.ant-picker-footer-extra) {
            border: none;
            font-size: 0.75rem;
            line-height: normal;
            text-align: center;
            padding: 0 1rem;
          }
        }

        :global(.ant-picker-cell-selected) {
          :global(.ant-picker-cell-inner) {
            background: colors.$mmc-primary-010;
            border-radius: 0;
            border: 1px solid colors.$mmc-primary;
            color: colors.$mmc-primary;
          }
        }

        :global(.ant-picker-cell-today) {
          :global(.ant-picker-cell-inner) {
            border: 1px solid colors.$mmc-primary;
            border-radius: 0;

            &::before {
              border: none;
              border-radius: 0;
            }
          }
        }
      }

      .clear {
        color: colors.$mmc-soft-slate;
        text-decoration: underline;

        &:hover {
          color: colors.$mmc-slate;
        }
      }
    }
  }
}
