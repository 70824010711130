@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .component {
        :global {
          & .fr-tooltipContainerWrapper,
          & .fr-tourHighlightContainer {
            z-index: 10000;
          }

          & .fr-tourHighlightContainer {
            pointer-events: none;
          }

          & .fr-tooltipContentContainer {
            background-color: colors.$mmc-slate;
            color: white;

            & .fr-smallTitle,
            & .fr-smallSubtitle,
            & .fr-tooltipStepCounter {
              color: colors.$mmc-white;
            }

            & .fr-smallSubtitle {
              font-weight: 400;
            }

            & .fr-tooltipFooter {
              margin-top: 1rem;
              & > div > p {
                margin-bottom: 0;
              }

              & .fr-tooltipCTAContainer {
                height: 2rem;

                & .fr-button {
                  background-color: colors.$mmc-soft-slate-50;
                  color: colors.$mmc-slate;

                  // styles from .ant-btn
                  line-height: 1.5715;
                  //position: relative;
                  //display: inline-block;
                  //font-weight: 500;
                  //white-space: nowrap;
                  //text-align: center;
                  //background-image: none;
                  //border: 1px solid #d9d9d9;
                  //box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
                  //cursor: pointer;
                  //transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                  //-webkit-user-select: none;
                  //user-select: none;
                  //touch-action: manipulation;
                  height: 32px;
                  padding: 4px 15px;
                  font-size: 14px;
                  border-radius: 0.25rem;
                }
              }
            }
          }

          .fr-checklistStepContent {
            & > .fr-ctaContainer {
              & > .fr-button {
                background-color: colors.$mmc-primary;
                color: colors.$mmc-white;
              }
            }
          }

          .fr-announcementButton {
            color: colors.$mmc-white;
          }
        }
      }

      .center {
        :global {
          .fr-tourHighlightContainer {
            display: none;
          }

          .fr-tooltipContainerWrapper {
            // need !important here since Frigade uses inline styles
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translateX(-50%) translateY(-50%) !important;
          }
        }
      }
    }
  }
}
