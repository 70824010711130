@use "styles/colors";
@use "styles/layout";
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .required {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;

        &::after {
          color: colors.$mmc-red-100;
          content: "*";
          display: inline-block;
          font-size: 0.875rem;
          font-weight: normal;
          line-height: 1;
        }
      }

      .dropzone {
        background-color: colors.$mmc-soft-gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3rem;
        margin: layout.$spacer-s 0;
        cursor: pointer;
        border: 1px dashed colors.$mmc-accent-gray;
        border-radius: 0.25rem;
        &:focus {
          border-color: colors.$mmc-primary-100;
        }
      }

      .filesTitleDiv {
        display: flex;
        justify-content: space-between;
        color: colors.$mmc-soft-slate;
      }

      .dropzoneTitle {
        margin: 0;
        font-size: 0.75rem;
        color: colors.$mmc-slate;
        pointer-events: none;
      }

      .dropzoneErrored {
        border-color: colors.$mmc-red-075;
        box-shadow: 0 0 0.25rem 0 #{color.adjust(colors.$mmc-red-100, $alpha: -0.5)};
      }

      .draggedInDropZone {
        border-color: colors.$mmc-primary-100;
      }

      .filesList {
        list-style-type: none;
        margin: 0;
        padding: 0;
        max-height: 7rem;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .fileRow {
        color: colors.$mmc-soft-slate;
        & :global(.ant-col) {
          display: inline-flex;
          align-items: center;
        }
      }

      .fileRowLabel {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .clickableFileRow {
        cursor: pointer;
      }

      .erroredFileRow {
        color: colors.$mmc-red-100;
      }

      .errorMessage {
        display: block;
        color: colors.$mmc-red-100;
        margin-bottom: 0.5rem;
      }
    }
  }
}
