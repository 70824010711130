@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .inheritAddress {
        padding: 0.25rem 0.75rem;
        background-color: colors.$mmc-soft-gray;
        margin-top: 0.625rem;
        color: colors.$mmc-soft-slate;
        font-weight: 500;
        cursor: pointer;
      }
      .autoFilledAddressAlert {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      .autoFill {
        display: flex;
        justify-content: center;
      }
    }
  }
}
