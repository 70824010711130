@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        background-color: colors.$mmc-soft-gray;
        min-height: 0;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 1rem 1rem 0;
        height: 100%;
      }
      .previewText {
        font-size: 0.875rem;
        color: colors.$mmc-soft-slate;
      }
      .previewBody {
        background-color: colors.$mmc-white;
        padding: 1rem;
        overflow: auto;
      }
    }
  }
}
