:global {
  body[class*="theme-"].theme {
    :local {
      .shiftToTop {
        & > :global(.ant-form-item-row) > :global(.ant-form-item-control) {
          margin-top: -1.375rem;
        }
      }

      .entityIcon {
        width: 1.25rem;
        height: 1.25rem;
        font-size: 0.625rem;
      }
    }
  }
}
