@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .description {
        margin-top: 1rem;
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
      }
    }
  }
}
