:global {
  body[class*="theme-"].theme {
    :local {
      .container {
      }

      .map {
        width: 100%;
        height: 100%;
      }

      .noLocationMessageContainer {
        display: grid;
        place-content: center;
      }
    }
  }
}
