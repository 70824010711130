@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: layout.$spacer-s;
        width: 2rem;
      }
      .readController {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        &:after {
          content: "";
          border-radius: 50%;
          width: 0.6rem;
          height: 0.6rem;
        }
        &Hovered {
          background-color: colors.$mmc-white;
          border: 0.5px solid colors.$mmc-accent-gray;
        }
        &:hover {
          &:after {
            background-color: colors.$mmc-accent-gray;
          }
        }
      }
      .unread {
        &Hovered {
          background-color: colors.$mmc-white;
        }
        &:after {
          border-radius: 50%;
          background-color: colors.$mmc-primary-100;
        }
        &:hover {
          &:after {
            background-color: colors.$mmc-primary-100;
          }
        }
      }
      .moreButtonActive {
        background-color: colors.$mmc-soft-gray;
        border-color: colors.$mmc-soft-gray;
        &:hover {
          background-color: colors.$mmc-soft-gray;
          border-color: colors.$mmc-soft-gray;
        }
      }
      .moreIcon {
        color: colors.$mmc-gray;
      }
    }
  }
}
