:global {
  body[class*="theme-"].theme {
    :local {
      .setPasswordButton {
        width: 100%;
      }

      .wrongLinkCard {
        display: block;
        width: 75%;
        margin: 0 auto;
      }

      .returnLink {
        margin-top: 2rem;
        display: block;
        width: 100%;
      }

      .returnButton {
        width: 80%;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
