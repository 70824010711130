@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .addButton {
        border-radius: 0.25rem;
        border: 1px dashed colors.$mmc-disabled-gray;
        padding: 1px 0.5rem;
        color: colors.$mmc-soft-slate;
        cursor: pointer;
        &:hover {
          color: colors.$mmc-slate;
          background: colors.$mmc-soft-gray;
        }
      }
    }
  }
}
