@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .title {
        font-size: 0.875rem;
        line-height: 1rem;
        color: colors.$mmc-soft-slate;
      }

      .icon {
        color: colors.$mmc-accent-gray;
      }
    }
  }
}
