@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        font-size: 0.875rem;
        font-weight: 500;
        color: colors.$mmc-slate;
        vertical-align: -0.15em; // not quite sure about this one, but looks ok. Necessary because of a big icon
        min-width: 0;
      }

      .icon {
        flex-shrink: 0;
      }

      .text {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .star {
        color: colors.$mmc-yellow-100;
      }
    }
  }
}
