@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: inline-flex;
        align-items: center;
      }

      // only set large font size when not in overflowed submenu
      :global(.ant-menu-root) .container {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1rem; // this helps to guarantee menu items would not be taller than 60px
        padding: 0 0.75rem;
        height: 60px;

        &,
        & > .link {
          color: colors.$mmc-slate;
        }
      }

      :global(.ant-menu-root) :global(.ant-menu-submenu-open) .container {
        &,
        & > .link {
          color: colors.$mmc-soft-slate;
        }
      }

      .icon {
        width: 1rem;
        height: 1rem;
      }

      .icon + span {
        margin-left: 0.25rem;
      }

      :global(.ant-menu-root) .link:before {
        bottom: 0;
      }

      // only display chevron when it is a root menu element in overflow menu
      .chevron {
        display: none;
        margin-left: 0.25rem;
        font-size: 0.75rem;
      }
      :global(.ant-menu-root) :global(.ant-menu-submenu-title) > .container > .chevron {
        display: inline-block;
      }

      .active {
        &,
        & > .link {
          color: colors.$mmc-primary;
        }
      }

      :global(.ant-menu-root) .active {
        height: 60px - 3px;
        border-bottom: 3px solid colors.$mmc-primary;
      }
    }
  }
}
