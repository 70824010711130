@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
      }
      .iconWrapper {
        position: relative;
        height: 1.125rem;
        width: 1.5rem;
      }
      .icon {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.125rem;
        cursor: pointer;
      }
    }
  }
}
