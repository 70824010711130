@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: layout.$spacer-l;
      }
    }
  }
}
