:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding: 0.125rem !important; // override padding style from parent
      }

      .avatar > :global(.ant-avatar) {
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
      }
    }
  }
}
