@use "styles/colors";
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .title {
        font-weight: 500;
      }

      .item {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
      }

      .position {
        padding: 0 0.5rem;
        white-space: nowrap;
      }

      .method {
        width: 8rem;
      }

      .unit {
        width: 7rem;
      }

      .position {
        font-size: 0.875rem;
      }

      .option {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }

      .icon {
        color: colors.$mmc-slate;
      }

      .deleteIcon {
        color: colors.$mmc-disabled-gray;
      }
    }
  }
}
