@use "../../../../styles/colors";
@use "../../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding: layout.$spacer-m;
        background-color: colors.$mmc-primary-010;
        color: colors.$mmc-slate;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }

      .icon {
        font-size: 36px;
        color: colors.$mmc-primary-100;
        margin-right: layout.$spacer-s;
      }

      .message {
        margin-right: auto;
        display: flex;

        & p {
          margin-bottom: layout.$spacer-s;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .actions {
        margin-left: auto;
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: layout.$spacer-s;
      }
    }
  }
}
