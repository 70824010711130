:global {
  body[class*="theme-"].theme {
    :local {
      .coordinate {
        width: 100%;
      }
      .searchManualButton {
        font-size: 0.75rem;
      }

      .coordinates {
        display: flex;
        width: 100%;
        gap: 0.5rem;
      }

      .modeBtn {
        font-weight: 500;
      }
    }
  }
}
