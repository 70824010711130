@use "styles/colors";
@use "sass:color";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        & > :global(.ant-popover-open) {
          .tooltip {
            display: none;
          }

          & > .btn:not(.btnDisabled) {
            background-color: colors.$mmc-primary-010;
            box-shadow: 0 0 0.25rem #{color.change(colors.$mmc-primary-075, $alpha: 0.5%)};
            color: colors.$mmc-primary-100;
          }

          & > .btnDisabled {
            background: colors.$mmc-soft-gray;
            box-shadow: 0 0 0.25rem #{color.change(colors.$mmc-accent-gray, $alpha: 0.5%)};
            color: colors.$mmc-slate;
          }
        }
      }

      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .btn {
        background-color: colors.$mmc-primary-010;
        border: none;
        box-shadow: none;
        color: colors.$mmc-primary-100;
        outline: none;

        &[ant-click-animating-without-extra-node]:after {
          display: none;
        }

        &:global(.ant-btn):hover {
          box-shadow: 0 0.25rem 0.75rem #{color.change(colors.$mmc-black, $alpha: 0.05%)};
        }

        &.btnDisabled {
          background: colors.$mmc-soft-gray;
          color: colors.$mmc-slate;

          &.past {
            box-shadow: none;
          }

          & > :global(.ant-btn) {
            &:disabled,
            &[disabled] {
              background: colors.$mmc-soft-gray;
              border: none;
              color: colors.$mmc-disabled-gray;

              .icon {
                color: colors.$mmc-disabled-gray;
              }
            }
          }
        }

        &:not(.btnDisabled) {
          &:hover {
            background-color: colors.$mmc-primary-010;
            color: colors.$mmc-slate;
          }
        }
      }

      .popover {
        padding-top: 0.25rem;

        :global(.ant-popover-arrow) {
          display: none;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        gap: 0.625rem;
        width: 25rem;

        .title {
          display: flex;
          gap: 0.5rem;
          font-weight: 500;
        }

        .list {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        .addBtn {
          background-color: colors.$mmc-primary-010;
          border: none;
          color: colors.$mmc-primary-100;
        }
      }
    }
  }
}
