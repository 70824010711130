@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.ant-collapse-header) {
          text-transform: uppercase;
        }
      }

      .activityList {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }
      .activity {
        width: 100%;
        border-radius: 0.25rem;
        border: colors.$mmc-accent-gray 1px solid;
        padding: 0.5rem;
        &:hover {
          background-color: colors.$mmc-soft-gray;
        }
      }
      .activityState {
        display: flex;
        align-items: center;
      }
      .iconVerified {
        color: colors.$mmc-primary-100;
      }
      .iconDone {
        color: colors.$mmc-green-100;
      }
      .note {
        margin-top: 0.5rem;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: colors.$mmc-slate;
        font-size: 0.875rem;
      }
      .noNote {
        color: colors.$mmc-disabled-gray;
      }
      .noActivities {
        color: colors.$mmc-disabled-gray;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 700;
      }
      .overdueIcon {
        color: colors.$mmc-red-100;
      }
    }
  }
}
