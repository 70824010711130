@use "../../../styles/layout";

$mmc-navbar-logo-max-width-value: 200;
$mmc-navbar-logo-max-width: $mmc-navbar-logo-max-width-value + 0px;

:export {
  breakdownWidth: layout.$breakpoint-mobile-value;
  maxWidth: $mmc-navbar-logo-max-width-value;
}

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: inline-flex;
        align-self: center;
        max-height: 100%;
      }

      .logo {
        max-height: 100%;
        min-width: calc(60px - 8px); // navbar height minus something for padding
        width: $mmc-navbar-logo-max-width;
        max-width: $mmc-navbar-logo-max-width;
        transition: width 0.5s ease-in-out;
        @media screen and (max-width: layout.$breakpoint-mobile) {
          min-width: 3rem;
          max-width: 3rem;
        }
      }
    }
  }
}
