:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        margin: 0 1rem;
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
      }
      .button {
        box-shadow: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
