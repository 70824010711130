:global {
  body[class*="theme-"].theme {
    :local {
      .menu {
        width: 9rem !important; // prevents right menu from collapsing

        & svg {
          // larger icons
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }
}
