:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
