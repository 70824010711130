@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        font-size: 0.75rem;
        font-weight: 400;
        display: flex;
        gap: 0.25rem;
        align-items: center;
      }
      .groupIcon {
        width: 0.3rem;
        color: colors.$mmc-white;
      }
      .groupIconContainer {
        height: 0.75rem;
        width: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .text {
        font-weight: 500;
        color: colors.$mmc-slate;
      }
    }
  }
}
