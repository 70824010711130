:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
      }

      .selectField,
      .daysField {
        flex: 1;
        min-width: 0;
      }
    }
  }
}
