@use "../../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .iconWrapper {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.75rem;
      }
      .accounts {
        background-color: colors.$mmc-primary-100;
      }
      .contacts {
        background-color: colors.$mmc-green-100;
      }
      .entityTypeIcon {
        width: 0.5rem;
      }
    }
  }
}
