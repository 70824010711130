@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .name {
        align-self: center;
        color: colors.$mmc-soft-slate;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .nameWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        & > *:first-child {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .value {
        color: colors.$mmc-slate;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
          // This addresses issue with bold font for annotation popover in Google Maps popover
          font-weight: inherit;
        }
      }
      .calculatedFieldInfo {
        margin-left: 0.5rem;
      }
    }
  }
}
