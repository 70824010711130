@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        :global(.ant-collapse-header) {
          text-transform: uppercase;
        }
      }
      .suggestions {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
      }
      .suggestion {
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        border: 1px colors.$mmc-accent-gray solid;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        background: colors.$mmc-white;
      }
      .suggestionTitle {
        font-size: 0.875rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        align-items: center;
      }
      .suggestionDate {
        border: 1px colors.$mmc-accent-gray solid;
        background-color: colors.$mmc-soft-gray;
        border-radius: 0.25rem;
        padding: 0.1rem 0.5rem;
        font-weight: 400;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      .activityTypeList {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.75rem;
        span {
          white-space: nowrap;
        }
        & :global(.ant-tag) {
          display: flex;
        }
      }
      .activityType {
        margin: 0;
      }
      .activityTypeOverflow {
        white-space: nowrap;
        &:hover {
          font-weight: 500;
        }
      }
      .activityTypeIcon {
        width: 0.875rem;
        height: 0.875rem;
        svg {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
      .activityScheduled {
        padding: 0.25rem;
        background-color: colors.$mmc-white;
        border-radius: 0.25rem;
      }
      .noFrequencyContainer {
        padding: 1rem;
      }
      .noFrequency {
        font-weight: 500;
      }
      .nextUp {
        text-transform: uppercase;
        color: colors.$mmc-slate;
        font-weight: 500;
        margin: 0.5rem 0;
      }
      .showMore {
        font-weight: 500;
      }
      .frequencyBox {
        padding: 0 0.5rem 0.25rem;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
      .frequencyBoxTypes {
        margin: 0 0.5rem;
      }
      .overflowTooltip {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
      }
    }
  }
}
