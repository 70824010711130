@use "styles/layout";
@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-right: layout.$spacer-m;
      }

      .logout {
        &:hover {
          color: colors.$mmc-red-100;
          background-color: colors.$mmc-white;
        }
      }

      .userMenuItem {
        li[role="menuitem"]:not(:first-child) {
          padding: layout.$spacer-s layout.$spacer-l;
          color: colors.$mmc-primary-100;

          a {
            color: colors.$mmc-primary-100;

            &:hover {
              color: colors.$mmc-soft-slate;
              background-color: colors.$mmc-white;
            }
          }

          &:hover {
            color: colors.$mmc-soft-slate;
            background-color: colors.$mmc-white;
          }
        }
      }

      .userProfileInfo {
        &:hover {
          background-color: colors.$mmc-white;
        }
      }

      .avatarDot {
        :global(.ant-badge-dot) {
          display: none;
          background: colors.$mmc-primary;
        }
      }
      .avatarDotVisible {
        :global(.ant-badge-dot) {
          display: block;
        }
      }
    }
  }
}
