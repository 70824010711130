:global {
  body[class*="theme-"].theme {
    :local {
      .vertical {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
