:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        &
          :global(.ant-select)
          > :global(.ant-select-selector)
          > :global(.ant-select-selection-item) {
          & > .avatar {
            display: none;
          }
        }
      }

      .allUsersOption {
      }

      .allUsersIcon,
      .avatar:global(.ant-avatar) {
        width: 1.25rem;
        margin-right: 0.125rem;
        [dir="rtl"] & {
          margin-right: 0;
          margin-left: 0.125rem;
        }
      }

      .avatar:global(.ant-avatar) {
        height: 1.25rem;
        & > :global(.ant-avatar-string) {
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
      }
    }
  }
}
