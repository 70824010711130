@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .modal {
        :global(.ant-modal-body) {
          min-height: 31rem;
          display: flex;
          gap: 1.5rem;
          height: 100%;
        }
      }
      .noPreview {
        background-color: colors.$mmc-soft-gray;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .preview {
        flex: 6;
        min-height: 0;
        overflow: auto;
        max-height: 80vh;
      }
      .modal {
        z-index: 1036; // we need to cover at lease activity annotation modal and email modal
      }
      .selectorContainer {
        flex: 4 0 50px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
      .recipientSelector {
        width: 100%;
      }
      .previewAs {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        font-weight: 400;
      }
      .noPreviewTitle {
        color: colors.$mmc-disabled-gray;
        font-size: 1.25rem;
        font-weight: 400;
      }
      .noPreviewDescription {
        color: colors.$mmc-disabled-gray;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
}
