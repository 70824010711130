@use "../../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: layout.$spacer-xs;
      }

      .select {
        width: 100%;
      }

      .reasonOption {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .reasonName {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
