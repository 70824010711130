@use "../../styles/colors";

$mmc-activity-type-color-list: "default" colors.$mmc-soft-slate colors.$mmc-soft-gray
    colors.$mmc-white,
  "visit" colors.$mmc-primary-100 colors.$mmc-primary-010 colors.$mmc-white,
  "call" colors.$mmc-green-100 colors.$mmc-green-010 colors.$mmc-black,
  "email" #ff6f1b #fff1e9 colors.$mmc-black, "meeting" #40e0d0 #ecfcfb colors.$mmc-black,
  "task" #ff5d6c #ffeff1 colors.$mmc-black, "lunch" #ffa700 #fff7e6 colors.$mmc-black,
  "text" colors.$mmc-violet-100 #f4e8fc colors.$mmc-white;

// if you change this list, don't forget to update apps/web-app/src/enum/CustomActivityTypeColor.ts
$mmc-activity-type-custom-color-list: "forest" #2e7d32 #eaf2ea colors.$mmc-white,
  "grape" #7b1fa2 #f2e9f6 colors.$mmc-white, "mandarin" #dd2c00 #fceae5 colors.$mmc-white,
  "ocean" #008080 #e5f2f2 colors.$mmc-white, "watermelon" #d81b60 #fbe8ef colors.$mmc-white,
  "royalBlue" #000080 #e5e5f2 colors.$mmc-white, "moss" #556b2f #eef0ea colors.$mmc-white,
  "lilac" #9237d9 #f4ebfb colors.$mmc-white, "amber" #cb0303 #fae6e6 colors.$mmc-white,
  "coffee" #4e342e #edebea colors.$mmc-white, "orchid" #b549a2 #f8edf6 colors.$mmc-white,
  "terracotta" #bf360c #f9ebe7 colors.$mmc-white, "denim" #01579b #e6eef5 colors.$mmc-white,
  "olive" #827717 #f2f1e8 colors.$mmc-white, "purple" #880e4f #f3e7ed colors.$mmc-white,
  "seaweed" #006064 #e5efef colors.$mmc-white, "latte" #795548 #f2eeed colors.$mmc-white,
  "maroon" #aa133f #f6e7ec colors.$mmc-white, "stone" #546e7a #eef0f2 colors.$mmc-white,
  "midnight" #263238 #e9eaeb colors.$mmc-white;
