@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding: 0.5rem 1rem;
      }
      .text {
        color: colors.$mmc-disabled-gray;
        margin-right: 1rem;
      }
    }
  }
}
