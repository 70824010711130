:global {
  body[class*="theme-"].theme {
    :local {
      .hubspot {
        background: #fef1e9;
        border: 1px solid #fbb384;
        svg {
          color: #fbb384;
        }
      }
      .salesforce {
        background: #e8f5fb;
        border: 1px solid #80c9e9;
        svg {
          color: #80c9e9;
        }
      }
      .zoho {
        background: rgba(152, 208, 160, 0.2);
        border: 1px solid #98d0a0;
        svg {
          color: #98d0a0;
        }
      }
      .dynamics {
        background: #fdf1f0;
        border: 1px solid #f3b8b4;
        svg {
          color: #f3b8b4;
        }
      }
    }
  }
}
