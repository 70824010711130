@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .nestedItem {
        margin-left: layout.$spacer-m;
      }
    }
  }
}
