:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.25rem;
        width: 100%;
      }

      .iconContainer {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
      }

      .icon {
        width: 0.5rem;
        height: 0.5rem;
      }

      .link {
        font-size: 0.875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
