@use "../../../styles/colors";
@use "../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        color: colors.$mmc-white;
        background-color: colors.$mmc-red-100;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 3rem;
        font-size: 1rem;
        font-weight: 500;
        padding: 0 2rem;
      }

      .link {
        z-index: 1;
      }

      .icon {
        font-size: 1.5rem;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
      }

      .text {
        display: inline;
        & > span:first-child {
          margin-right: 0.25rem;
        }
      }

      @media screen and (max-width: layout.$breakpoint-mobile) {
        .container {
          height: auto;
          padding: 1rem;
        }
        .text {
          display: flex;
          flex-direction: column;
          & > span {
            font-weight: 400;
            display: block;
            font-size: 0.875rem;
            &:first-child {
              font-size: 1.125rem;
              font-weight: 500;
              margin-bottom: 0.5rem;
            }
          }
        }
        .icon {
          font-size: 3rem;
        }
      }
    }
  }
}
