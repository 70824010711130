:global {
  body[class*="theme-"].theme {
    :local {
      .hidden {
        display: none;
      }

      .activityTypeDropdown {
        z-index: 2001;
      }
    }
  }
}
